import { useEffect, useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import { IconButton } from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

export default function UserMap({ address }) {
  const ref = useRef(null);
  const { pathname } = useLocation();
  const [position, setPosition] = useState([]);
  useEffect(() => {
    setPosition([address?.latitude, address?.longitude]);
  }, [address]);

  const [openMap, setOpenMap] = useState(false);

  const handleClickOpenMap = () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };
  return (
    <>
      <IconButton
        ref={ref}
        onClick={handleClickOpenMap}
        disabled={
          address?.latitude === null ||
          address?.latitude === "" ||
          address?.longitude === null ||
          address?.longitude === "" ||
          position === []
        }
      >
        <Iconify icon="ic:baseline-remove-red-eye" width={20} height={20} />
      </IconButton>

      <Dialog
        disableEscapeKeyDown
        open={openMap}
        onClose={handleCloseMap}
        fullWidth
      >
        <DialogTitle>
          {" "}
          {pathname === "/dashboard/stores"
            ? "موقع المتجر"
            : "موقع المستخدم"}{" "}
        </DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <Box id="map">
            <MapContainer
              center={position}
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: 350 }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={position}></Marker>
            </MapContainer>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleCloseMap}>إغلاق</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
