import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateFcmToken } from "./constants/urls";

const firebaseConfig = {
  apiKey: "AIzaSyDapRB9q99QHB20ne_RpEk_8ZWh6GgPnts",
  authDomain: "shoukra-card.firebaseapp.com",
  projectId: "shoukra-card",
  storageBucket: "shoukra-card.appspot.com",
  messagingSenderId: "245332197767",
  appId: "1:245332197767:web:d13949199f22806bbd5322",
  measurementId: "G-6MSMHRRZZD",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async (token) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BNsw6a4PpGaHg3crpd7pJba0zsqxlk1zv1YnewbZCsGEISPCgIE0J8uCniBS6k202MBIjdkFHxlCdsxBClcEFKE",
    });
    if (currentToken) {
      // console.log("currentToken", currentToken);
      if (token) {
        axios.post(
          updateFcmToken,
          { fcm_token: currentToken },
          {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
            },
          }
        );
      }
      // .then((response) => {
      //   console.log(response);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
