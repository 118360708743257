import { useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { ComplaintUrl, OrdersUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosPost from "../../../utils/axiosPost";

// ----------------------------------------------------------------------

export default function ComplaintMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Complaint Status
  */

  const [openEditComplaintStatus, setOpenEditComplaintStatus] = useState(false);
  const [complaintStatus, setComplaintStatus] = useState(user.status);

  const handleChangeComplaintStatus = (event) => {
    setComplaintStatus(event.target.value);
  };

  const handleClickopenEditComplaintStatus = () => {
    setOpenEditComplaintStatus(true);
  };

  const handleCloseEditComplaintStatus = () => {
    setOpenEditComplaintStatus(false);
  };

  const handleSubmitEditComplaintStatus = () => {
    setLoadingUserBtn(true);
    const value = {
      status: complaintStatus,
    };

    axiosPost(
      `${ComplaintUrl}/${user.id}?_method=PUT`,
      value,
      setLoadingUserBtn,
      setOpenEditComplaintStatus,
      setIsOpen
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Edit Status */}
        {roles.includes("Update-Complaint") && (
          <MenuItem
            id="printPageButton"
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenEditComplaintStatus}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="tabler:status-change" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل حالة الشكوى"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          id="printPageButton"
          disableEscapeKeyDown
          open={openEditComplaintStatus}
          onClose={handleCloseEditComplaintStatus}
        >
          <DialogTitle>تعديل حالة الشكوى</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-dialog-select-label">
                  حالة الشكوى
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={complaintStatus}
                  onChange={handleChangeComplaintStatus}
                  input={<OutlinedInput label="حالة الشكوى" />}
                >
                  <MenuItem value={"new"}>جديدة</MenuItem>
                  <MenuItem value={"solved"}>تم الحل</MenuItem>
                  <MenuItem value={"rejected"}>مرفوضة</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditComplaintStatus}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditComplaintStatus}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
