import { useEffect, useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import { TextField } from "@mui/material";
// component
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { updateInfoUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosPost from "../../../utils/axiosPost";

// ----------------------------------------------------------------------

export default function InfoMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  /* 
     Update User
  */

  const [openEditInfo, setOpenEditInfo] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [website, setWebsite] = useState("");
  const [fb, setFb] = useState("");
  const [instagram, setInstagram] = useState("");
  // const [linkedin, setLinkedin] = useState("");
  useEffect(() => {
    if (user.length > 0) {
      setPhoneNumber(user[0]?.phone_number);
      setEmail(user[0]?.email);
      setLocation(user[0]?.location);
      setWebsite(user[0]?.website);
      setFb(user[0]?.fb);
      setInstagram(user[0]?.instagram);
      // setLinkedin(user[0]?.linkedin);
    }
  }, [user]);

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleChangeWebsite = (event) => {
    setWebsite(event.target.value);
  };

  const handleChangeFb = (event) => {
    setFb(event.target.value);
  };

  const handleChangeInstagram = (event) => {
    setInstagram(event.target.value);
  };

  // const handleChangeLinkedin = (event) => {
  //   setLinkedin(event.target.value);
  // };

  const handleClickopenEditInfo = () => {
    setOpenEditInfo(true);
  };

  const handleCloseEditInfo = () => {
    setOpenEditInfo(false);
  };

  const handleSubmitEditInfo = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    phoneNumber !== user[0]?.phone_number &&
      formData.append("phone_number", phoneNumber);
    email !== user[0]?.email && formData.append("email", email);
    location !== user[0]?.location && formData.append("location", location);
    website !== user[0]?.website && formData.append("website", website);
    fb !== user[0]?.fb && formData.append("fb", fb);
    instagram !== user[0]?.instagram && formData.append("instagram", instagram);
    // linkedin !== user[0]?.linkedin && formData.append("linkedin", linkedin);
    axiosPost(
      `${updateInfoUrl}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditInfo,
      setOpenEditInfo
    );
  };

  return (
    <>
      <Button
        variant="contained"
        ref={ref}
        onClick={handleClickopenEditInfo}
        sx={{ margin: "1rem" }}
      >
        تعديل معلومات التطبيق
      </Button>

      <Dialog
        disableEscapeKeyDown
        open={openEditInfo}
        onClose={handleCloseEditInfo}
      >
        <DialogTitle>تعديل معلومات التطبيق</DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <Box component="form">
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <TextField
                id="outlined-basic"
                label="رقم الهاتف"
                variant="outlined"
                onChange={handleChangePhoneNumber}
                value={phoneNumber}
              />
            </FormControl>
          </Box>
          <Box component="form">
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <TextField
                id="outlined-basic"
                label="البريد الالكتروني"
                variant="outlined"
                onChange={handleChangeEmail}
                value={email}
              />
            </FormControl>
          </Box>
          <Box component="form">
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <TextField
                id="outlined-basic"
                label="الموقع"
                variant="outlined"
                onChange={handleChangeLocation}
                value={location}
              />
            </FormControl>
          </Box>
          <Box component="form">
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <TextField
                id="outlined-basic"
                label="الموقع الالكتروني"
                variant="outlined"
                onChange={handleChangeWebsite}
                value={website}
              />
            </FormControl>
          </Box>
          <Box component="form">
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <TextField
                id="outlined-basic"
                label="حساب الفيس بوك"
                variant="outlined"
                onChange={handleChangeFb}
                value={fb}
              />
            </FormControl>
          </Box>
          <Box component="form">
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <TextField
                id="outlined-basic"
                label="حساب الانستاغرام"
                variant="outlined"
                onChange={handleChangeInstagram}
                value={instagram}
              />
            </FormControl>
          </Box>
          {/* <Box component="form">
            <FormControl sx={{ m: 1, minWidth: 350 }}>
              <TextField
                id="outlined-basic"
                label="حساب اللينكد ان"
                variant="outlined"
                onChange={handleChangeLinkedin}
                value={linkedin}
              />
            </FormControl>
          </Box> */}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleCloseEditInfo}>
            {t("description.Cancel")}
          </Button>
          <LoadingButton
            loading={loadingUserBtn}
            onClick={handleSubmitEditInfo}
          >
            {t("description.Ok")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
