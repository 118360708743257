import axios from "axios";
import Swal from "sweetalert2";

const axiosPostStore = (
  URL,
  data,
  setLoading,
  openDialog,
  openListItem,
  resetFields = [],
  resetArray = [],
  setUserId,
  setActiveStep,
  setOpenNewStore,
  setSnackBarMessage,
  setOpenSnackBar
) => {
  const token = localStorage.getItem("STKN");

  axios
    .post(URL, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    })
    .then((response) => {
      setLoading(false);
      openDialog(false);
      openListItem(false);
      setUserId(response.data.data.id);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setOpenSnackBar(true);
      resetFields.map((item) => {
        item("");
      });
      resetArray.map((item) => {
        item([]);
      });
      setSnackBarMessage(response.data.message);
    })
    .catch((error) => {
      openDialog(false);
      setLoading(false);
      openListItem(false);
      setOpenNewStore(false);

      Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
    });
};

export default axiosPostStore;
