// material
import {
  Box,
  Grid,
  Container,
  Typography,
  LinearProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
  AppConversionRates,
} from "../sections/@dashboard/app";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChartsUrl } from "../constants/urls";
import axiosGet from "../utils/axiosGet";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { t } = useTranslation();
  const token = localStorage.getItem("STKN");
  const roles = JSON.parse(localStorage.getItem("SRS"));
  let navigate = useNavigate();
  const [charts, setCharts] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (token === null) {
      navigate("/");
    } else if (roles.includes("show-charts")) {
      axiosGet(ChartsUrl, setCharts, setIsLoading, setError);
    } else {
      setIsLoading(false);
    }
  }, [token]);

  return isLoading ? (
    <LinearProgress />
  ) : (
    <Page title="شكراً | لوحة التحكم">
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <Typography variant="h4">
            {t("description.dashboardAppWelcome")} {localStorage.getItem("SUN")}
          </Typography>
        </Box>
        <hr />
        {roles.includes("show-charts") ? (
          <>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5">احصائيات عامة</Typography>
            </Box>
            <Grid container spacing={3} sx={{ marginBottom: "2rem" }}>
              <Grid item xs={12} sm={4} md={4}>
                <AppWeeklySales total_number_of_orders={charts?.ordersNumber} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <AppItemOrders UnVerifiedUsers={charts?.productsNumber} />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <AppNewUsers verifiedUsers={charts?.usersNumber} />
              </Grid>
            </Grid>
            <hr />
            <Box sx={{ p: 2 }}>
              <Typography variant="h5">احصائيات حالات الطلبات</Typography>
            </Box>
            <Grid container spacing={3} sx={{ marginBottom: "2rem" }}>
              {charts?.ordersStatus?.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <AppBugReports DailyNewUsers={item} />
                </Grid>
              ))}
            </Grid>
            <hr />
            <Grid container spacing={3} sx={{ margin: "2rem 0" }}>
              <Grid item xs={12} md={12} lg={12}>
                <AppConversionRates userCity={charts?.governorateUsers} />
              </Grid>
            </Grid>
            <hr />
            <Box sx={{ p: 2 }}>
              <Typography variant="h5">تقييم المنتجات</Typography>
            </Box>
            <Grid container spacing={3} sx={{ margin: "1rem 0" }}>
              <Grid item xs={12} md={6} lg={6}>
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align={"right"}>
                          المنتجات الأعلى تقييماً
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {charts?.topProducts?.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell align={"right"}>{item?.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align={"right"}>
                          المنتجات الأدنى تقييماً
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {charts?.littleProducts?.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell align={"right"}>{item?.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Container>
    </Page>
  );
}
