import { useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { CategoriesUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";

// ----------------------------------------------------------------------

export default function CategoriesMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Delete user
  */
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);

  const handleClickopenDeleteCategory = () => {
    setOpenDeleteCategory(true);
  };

  const handleCloseDeleteCategory = () => {
    setOpenDeleteCategory(false);
  };

  const handleSubmitDeleteCategory = () => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${CategoriesUrl}/${user.id}`,
      setLoadingUserBtn,
      setOpenDeleteCategory
    );
  };

  /* 
     Update User
  */

  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [Username, setUsername] = useState(user.name);

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleClickOpenEditCategory = () => {
    setOpenEditCategory(true);
  };

  const handleCloseEditCategory = () => {
    setOpenEditCategory(false);
  };
  const [empty, setEmpty] = useState([]);

  const handleSubmitEditCategory = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    /* Username !== user.name && */ formData.append("name", Username);

    axiosPost(
      `${CategoriesUrl}/${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditCategory,
      setIsOpen,
      [setEmpty],
      [setEmpty]
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Delete user */}
        {roles.includes("Delete-Category") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenDeleteCategory}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف الفئة"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openDeleteCategory}
          onClose={handleCloseDeleteCategory}
        >
          <DialogTitle>حذف الفئة</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف هذه الفئة؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteCategory}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteCategory}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        {roles.includes("Update-Category") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditCategory}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل الفئة"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openEditCategory}
          onClose={handleCloseEditCategory}
        >
          <DialogTitle>تعديل الفئة</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="اسم الفئة"
                  variant="outlined"
                  onChange={handleChangeUsername}
                  value={Username}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditCategory}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditCategory}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
