import { filter } from "lodash";
import { getRolesUrl, userCreateUrl, userListUrl } from "../constants/urls";

/* import { sentenceCase } from "change-case"; */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Box,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  LinearProgress,
} from "@mui/material";

// components

import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu1,
} from "../sections/@dashboard/user";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import axiosGet from "../utils/axiosGet";
import axiosPost from "../utils/axiosPost";
import DatePicker from "../components/DatePicker";
import UserMap from "../sections/@dashboard/user/UserMap";
import GetUserLocation from "../sections/@dashboard/user/GetUserLocation";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function User() {
  const { t, i18n } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("SRS"));
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openNewUser, setOpenNewUser] = useState(false);
  const [loadingUserBtn, setLoadingUserBtn] = useState(false);
  const [Username, setUsername] = useState("");
  const [Phone, setPhone] = useState("");
  const [roleName, setRoleName] = useState("");
  const [City, setCity] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [bDate, setBDate] = useState("");
  const [password, setPassword] = useState("");
  // const [shippingCoast, setShippingCoast] = useState("");

  let navigate = useNavigate();
  const token = localStorage.getItem("STKN");
  const [usersList, setUsersList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [isLoadingRoles, setIsLoadingRoels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    token === null && navigate("/");
    axiosGet(userListUrl, setUsersList, setIsLoading, setError);
  }, [token, loadingUserBtn]);

  useEffect(() => {
    axiosGet(getRolesUrl, setRolesList, setIsLoadingRoels, setError);
  }, []);

  let filteredUsers = applySortFilter(
    usersList,
    getComparator(order, orderBy),
    filterName
  );
  const TABLE_HEAD = [
    {
      id: "name",
      label: "اسم المستخدم",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "phone_number",
      label: "رقم الهاتف",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    // {
    //   id: "shipping_coast",
    //   label: "عمولة الشحن",
    //   alignRight: i18n.dir() === "ltr" ? false : true,
    // },
    {
      id: "governorate",
      label: "المحافظة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "city",
      label: "المدينة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "address",
      label: "العنوان",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "accout_type",
      label: "نوع الحساب",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    { id: "" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) =>
          _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _user.phone_number
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const isUserNotFound = filteredUsers.length === 0;

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleChangeGovernorate = (event) => {
    setGovernorate(event.target.value);
  };
  const handleChangeRoleName = (event) => {
    setRoleName(event.target.value);
  };

  const handleChangeLatitude = (event) => {
    setLatitude(event.target.value);
  };

  const handleChangeLongtiude = (event) => {
    setLongitude(event.target.value);
  };

  // const handleChangeShippingCoast = (event) => {
  //   setShippingCoast(event.target.value);
  // };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleClickOpenNewUser = () => {
    setOpenNewUser(true);
  };

  const handleCloseNewUser = () => {
    setOpenNewUser(false);
  };

  const handleSubmitAddUser = () => {
    setLoadingUserBtn(true);

    const values = {
      name: Username,
      phone_number: Phone,
      password: password,
      password_confirmation: password,
      governorate: governorate,
      city: City,
      address: `${latitude}, ${longitude}`,
      birthday: bDate,
      role_name: roleName,
      // shipping_cost: shippingCoast,
    };
    axiosPost(
      userCreateUrl,
      values,
      setLoadingUserBtn,
      setOpenNewUser,
      setOpenNewUser,
      [
        setUsername,
        setPhone,
        setPassword,
        setGovernorate,
        setCity,
        setLatitude,
        setLongitude,
        setBDate,
        setRoleName,
        // setShippingCoast,
      ]
    );
  };
  return isLoading ? (
    <LinearProgress />
  ) : (
    <Page title="لوحة التحكم | المستخدمين">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            المستخدمين
          </Typography>
          {roles.includes("user-create") && (
            <Button variant="contained" onClick={handleClickOpenNewUser}>
              إضافة مستخدم
            </Button>
          )}
        </Stack>
        <Dialog
          disableEscapeKeyDown
          open={openNewUser}
          onClose={handleCloseNewUser}
        >
          <DialogTitle>إضافة مستخدم جديد</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="اسم المستخدم"
                  variant="outlined"
                  onChange={handleChangeUsername}
                  value={Username}
                />
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="رقم الهاتف"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangePhone}
                  value={Phone}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="كلمة السر"
                  variant="outlined"
                  onChange={handleChangePassword}
                  type="password"
                  value={password}
                />
              </FormControl>
            </Box>

            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-dialog-select-label">المحافظة</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={governorate}
                  onChange={handleChangeGovernorate}
                  input={<OutlinedInput label="المحافظة" />}
                >
                  <MenuItem value={"Tartous"}>طرطوس</MenuItem>
                  <MenuItem value={"Lattakia"}>اللاذقية</MenuItem>
                  <MenuItem value={"Damascus"}>دمشق</MenuItem>
                  <MenuItem value={"Damascus_countryside"}>ريف دمشق</MenuItem>
                  <MenuItem value={"Aleppo"}>حلب</MenuItem>
                  <MenuItem value={"Homs"}>حمص</MenuItem>
                  <MenuItem value={"Hama"}>حماه</MenuItem>
                  <MenuItem value={"Idlib"}>ادلب</MenuItem>
                  <MenuItem value={"Suwayda"}>السويداء</MenuItem>
                  <MenuItem value={"Daraa"}>درعا</MenuItem>
                  <MenuItem value={"Quneitra"}>القنيطرة</MenuItem>
                  <MenuItem value={"Deir_Ezzor"}>دير الزور</MenuItem>
                  <MenuItem value={"Al_Hasakah"}>الحسكة</MenuItem>
                  <MenuItem value={"Raqqa"}>الرقة</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="المدينة"
                  variant="outlined"
                  onChange={handleChangeCity}
                  value={City}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="خط العرض"
                  variant="outlined"
                  onChange={handleChangeLatitude}
                  value={latitude}
                  disabled
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="خط الطول"
                  variant="outlined"
                  onChange={handleChangeLongtiude}
                  value={longitude}
                  disabled
                />
              </FormControl>
            </Box>
            <GetUserLocation
              setLatitude={setLatitude}
              setLongitude={setLongitude}
            />

            {/* <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="عمولة الشحن"
                  variant="outlined"
                  onChange={handleChangeShippingCoast}
                  value={shippingCoast}
                />
              </FormControl>
            </Box> */}

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                {/* <TextField
                  
                  label="تاريخ الميلاد"
                  placeholder="DD-MM-YYYY"
                  variant="outlined"
                  type={"text"}
                  onChange={handleChangeBDate}
                  value={bDate}
                /> */}
                <DatePicker
                  title={"تاريخ الميلاد"}
                  setValue={setBDate}
                  value={bDate}
                  disablePast={false}
                />
              </FormControl>
            </Box>
            {!isLoadingRoles && (
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <InputLabel id="demo-dialog-select-label">
                    نوع المستخدم
                  </InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={roleName}
                    onChange={handleChangeRoleName}
                    input={<OutlinedInput label="نوع المستخدم" />}
                  >
                    {rolesList.map((item) => (
                      <MenuItem value={item.name} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseNewUser}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitAddUser}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Card>
          {error ? (
            <Box sx={{ padding: "2rem", color: "red" }}>
              {error.data.message === ""
                ? "حدث خطأ ما يرجى التواصل مع فريق الدعم"
                : error.data.message}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <UserListToolbar
                  placeHolder="ابحث هنا..."
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Box>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={usersList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const { id, name } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell component="th" scope="row" wrap>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      margin:
                                        i18n.dir() === "rtl" &&
                                        "0 0.5rem !important",
                                    }}
                                  >
                                    {name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.phone_number}
                              </TableCell>
                              {/* <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.shipping_cost !== null
                                  ? row.shipping_cost
                                      .toString()
                                      .replace(
                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                        "$1,"
                                      ) + "ل.س"
                                  : "لا يوجد"}
                              </TableCell> */}
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.governorate}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.city}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <UserMap address={row.address} />
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <Label
                                  variant="ghost"
                                  sx={{
                                    color:
                                      row.role_name === "SuperAdmin"
                                        ? "green"
                                        : "",
                                  }}
                                >
                                  {row.role_name}
                                </Label>
                              </TableCell>

                              {/* {roles.includes("user-list") && ( */}
                              <TableCell
                                align={i18n.dir() === "ltr" ? "right" : "left"}
                                padding="none"
                              >
                                <UserMoreMenu1
                                  user={row}
                                  token={token}
                                  loadingUserBtn={loadingUserBtn}
                                  setLoadingUserBtn={setLoadingUserBtn}
                                  roles={roles}
                                  rolesList={rolesList}
                                  isLoadingRoles={isLoadingRoles}
                                />
                              </TableCell>
                              {/* )} */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                sx={{ direction: "ltr" }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={usersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("description.UsersPageLabelRowsPerPage")}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
