import { useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { userListUrl, userNotificationUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import DatePicker from "../../../components/DatePicker";
import axiosPost from "../../../utils/axiosPost";
import GetUserLocation from "./GetUserLocation";
import GetUserLocationEdit from "./GetUserLocationEdit";

// ----------------------------------------------------------------------

export default function UserMoreMenu1({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
  rolesList,
  isLoadingRoles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Delete user
  */
  const [openDeleteUser, setOpenDeleteUser] = useState(false);

  const handleClickOpenDeleteUser = () => {
    setOpenDeleteUser(true);
  };

  const handleCloseDeleteUser = () => {
    setOpenDeleteUser(false);
  };

  const handleSubmitDeleteUser = () => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${userListUrl}/${user.id}`,
      setLoadingUserBtn,
      setOpenDeleteUser,
      setOpenDeleteUser
    );
  };

  /* 
     Update User
  */

  const [openEditUser, setOpenEditUser] = useState(false);
  const [Username, setUsername] = useState(user.name);
  const [Phone, setPhone] = useState(user.phone_number);
  const [roleName, setRoleName] = useState(user.role_name);
  const [City, setCity] = useState(user.city);
  const [governorate, setGovernorate] = useState(user.governorate_value);
  const [latitude, setLatitude] = useState(user.address.latitude);
  const [longitude, setLongitude] = useState(user.address.longitude);
  const [bDate, setBDate] = useState(user.birthday);
  // const [shippingCoast, setShippingCoast] = useState(user.shipping_cost);

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleChangeGovernorate = (event) => {
    setGovernorate(event.target.value);
  };
  const handleChangeRoleName = (event) => {
    setRoleName(event.target.value);
  };

  // const handleChangeShippingCoast = (event) => {
  //   setShippingCoast(event.target.value);
  // };

  const handleChangeLatitude = (event) => {
    setLatitude(event.target.value);
  };

  const handleChangeLongtiude = (event) => {
    setLongitude(event.target.value);
  };

  const handleClickOpenEditUser = () => {
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };
  const [empty, setEmpty] = useState([]);

  const handleSubmitEditUser = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    Username !== user.name && formData.append("name", Username);
    Phone !== user.phone_number && formData.append("phone_number", Phone);
    roleName !== user.role_name && formData.append("role_name", roleName);
    City !== user.city && formData.append("city", City);
    governorate !== user.governorate_value &&
      formData.append("governorate", governorate);
    latitude !== user.address.latitude &&
      formData.append("address", [latitude, longitude]);
    longitude !== user.address.longitude &&
      formData.append("address", [latitude, longitude]);
    bDate !== user.birthday && formData.append("birthday", bDate);
    // shippingCoast !== user.shipping_cost &&
    //   formData.append("shipping_cost", shippingCoast);
    axiosPost(
      `${userListUrl}/${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditUser,
      setIsOpen,
      [setEmpty],
      [setEmpty]
    );
  };

  /* Send Notificatiom */
  const [openSendNotification, setOpenSendNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");

  const handleClickOpenSendNotification = () => {
    setOpenSendNotification(true);
  };

  const handleCloseSendNotification = () => {
    setOpenSendNotification(false);
  };

  const handleChangeNotificationTitle = (event) => {
    setNotificationTitle(event.target.value);
  };
  const handleSubmitSendNotification = () => {
    setLoadingUserBtn(true);
    const value = {
      title: notificationTitle,
      fcm_token: user.fcm_token,
    };
    axiosPost(
      `${userNotificationUrl}`,
      value,
      setLoadingUserBtn,
      setOpenSendNotification,
      setIsOpen,
      [setNotificationTitle],
      [setEmpty]
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Delete user */}
        {roles.includes("user-delete") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenDeleteUser}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف حساب المستخدم"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openDeleteUser}
          onClose={handleCloseDeleteUser}
        >
          <DialogTitle> حذف حساب المستخدم</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف حساب هذا المستخدم ؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteUser}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteUser}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        {roles.includes("user-edit") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditUser}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل بيانات المستخدم"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openEditUser}
          onClose={handleCloseEditUser}
        >
          <DialogTitle>تعديل بيانات المستخدم</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="اسم المستخدم"
                  variant="outlined"
                  onChange={handleChangeUsername}
                  value={Username}
                />
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="رقم الهاتف"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangePhone}
                  value={Phone}
                />
              </FormControl>
            </Box>

            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-dialog-select-label">المحافظة</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={governorate}
                  onChange={handleChangeGovernorate}
                  input={<OutlinedInput label="المحافظة" />}
                >
                  <MenuItem value={"Tartous"}>طرطوس</MenuItem>
                  <MenuItem value={"Lattakia"}>اللاذقية</MenuItem>
                  <MenuItem value={"Damascus"}>دمشق</MenuItem>
                  <MenuItem value={"Damascus_countryside"}>ريف دمشق</MenuItem>
                  <MenuItem value={"Aleppo"}>حلب</MenuItem>
                  <MenuItem value={"Homs"}>حمص</MenuItem>
                  <MenuItem value={"Hama"}>حماه</MenuItem>
                  <MenuItem value={"Idlib"}>ادلب</MenuItem>
                  <MenuItem value={"Suwayda"}>السويداء</MenuItem>
                  <MenuItem value={"Daraa"}>درعا</MenuItem>
                  <MenuItem value={"Quneitra"}>القنيطرة</MenuItem>
                  <MenuItem value={"Deir_Ezzor"}>دير الزور</MenuItem>
                  <MenuItem value={"Al_Hasakah"}>الحسكة</MenuItem>
                  <MenuItem value={"Raqqa"}>الرقة</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="المدينة"
                  variant="outlined"
                  onChange={handleChangeCity}
                  value={City}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="خط العرض"
                  variant="outlined"
                  // onChange={handleChangeLatitude}
                  value={latitude}
                  disabled
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="خط الطول"
                  variant="outlined"
                  // onChange={handleChangeLongtiude}
                  value={longitude}
                  disabled
                />
              </FormControl>
            </Box>
            <GetUserLocationEdit
              longitude={longitude}
              latitude={latitude}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
            />
            {/* <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="عمولة الشحن"
                  variant="outlined"
                  onChange={handleChangeShippingCoast}
                  value={shippingCoast}
                />
              </FormControl>
            </Box> */}

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <DatePicker
                  title={"تاريخ الميلاد"}
                  setValue={setBDate}
                  value={bDate}
                  disablePast={false}
                />
              </FormControl>
            </Box>
            {!isLoadingRoles && (
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <InputLabel id="demo-dialog-select-label">
                    نوع المستخدم
                  </InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={roleName}
                    onChange={handleChangeRoleName}
                    input={<OutlinedInput label="نوع المستخدم" />}
                  >
                    {rolesList.map((item) => (
                      <MenuItem value={item.name} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditUser}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditUser}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* send notification user */}
        {roles.includes("send-notification") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenSendNotification}
            disabled={user.fcm_token === null}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify
                icon="material-symbols:notification-add"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="ارسال اشعار"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openSendNotification}
          onClose={handleCloseSendNotification}
        >
          <DialogTitle>ارسال اشعار إلى المستخدم</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  label="نص الاشعار"
                  variant="outlined"
                  onChange={handleChangeNotificationTitle}
                  value={notificationTitle}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseSendNotification}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitSendNotification}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
