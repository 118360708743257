import { useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Select,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import {
  phoneNumbersUrl,
  updatePhoneNumbersUrl,
} from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";

// ----------------------------------------------------------------------

export default function PhoneNumbersMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  /* 
     Update User
  */
  const [openEditPhone, setOpenEditCategory] = useState(false);
  const [city, setCity] = useState(user.governorate_value);
  const [phone1, setPhone1] = useState(user.first_phone_number);
  const [phone2, setPhone2] = useState(user.second_phone_number);

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleChangePhone1 = (event) => {
    setPhone1(event.target.value);
  };
  const handleChangePhone2 = (event) => {
    setPhone2(event.target.value);
  };

  const handleClickOpenEditPhone = () => {
    setOpenEditCategory(true);
  };

  const handleCloseEditPhone = () => {
    setOpenEditCategory(false);
  };

  const handleSubmitEditCategory = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    city !== user.governorate_value && formData.append("governorate", city);
    phone1 !== user.first_phone_number &&
      formData.append("first_phone_number", phone1);
    phone2 !== user.second_phone_number &&
      formData.append("second_phone_number", phone2);

    axiosPost(
      `${updatePhoneNumbersUrl}${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditCategory,
      setIsOpen
    );
  };
  /* 
     Delete user
  */
  const [openDeleteRecord, setOpenDeleteRecord] = useState(false);

  const handleClickopenDeleteProduct = () => {
    setOpenDeleteRecord(true);
  };

  const handleCloseDeleteRecord = () => {
    setOpenDeleteRecord(false);
  };

  const handleSubmitDeleteRecord = () => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${phoneNumbersUrl}/delete/${user.id}`,
      setLoadingUserBtn,
      setOpenDeleteRecord,
      setOpenDeleteRecord
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Edit user */}
        {roles.includes("Update-SupportPhone") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditPhone}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل السجل"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openEditPhone}
          onClose={handleCloseEditPhone}
        >
          <DialogTitle>تعديل السجل</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <InputLabel id="demo-dialog-select-label">
                    المحافظة
                  </InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={city}
                    onChange={handleChangeCity}
                    input={<OutlinedInput label="المحافظة" />}
                  >
                    <MenuItem value={"Tartous"}>طرطوس</MenuItem>
                    <MenuItem value={"Lattakia"}>اللاذقية</MenuItem>
                    <MenuItem value={"Damascus"}>دمشق</MenuItem>
                    <MenuItem value={"Damascus_countryside"}>ريف دمشق</MenuItem>
                    <MenuItem value={"Aleppo"}>حلب</MenuItem>
                    <MenuItem value={"Homs"}>حمص</MenuItem>
                    <MenuItem value={"Hama"}>حماه</MenuItem>
                    <MenuItem value={"Idlib"}>ادلب</MenuItem>
                    <MenuItem value={"Suwayda"}>السويداء</MenuItem>
                    <MenuItem value={"Daraa"}>درعا</MenuItem>
                    <MenuItem value={"Quneitra"}>القنيطرة</MenuItem>
                    <MenuItem value={"Deir_Ezzor"}>دير الزور</MenuItem>
                    <MenuItem value={"Al_Hasakah"}>الحسكة</MenuItem>
                    <MenuItem value={"Raqqa"}>الرقة</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <TextField
                    id="outlined-basic"
                    label="رقم 1"
                    variant="outlined"
                    onChange={handleChangePhone1}
                    type={"number"}
                    value={phone1}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <TextField
                    id="outlined-basic"
                    label="رقم 2"
                    type={"number"}
                    variant="outlined"
                    onChange={handleChangePhone2}
                    value={phone2}
                  />
                </FormControl>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditPhone}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditCategory}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {roles.includes("Update-SupportPhone") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenDeleteProduct}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف السجل"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openDeleteRecord}
          onClose={handleCloseDeleteRecord}
        >
          <DialogTitle>حذف السجل</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف هذا السجل؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteRecord}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteRecord}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
