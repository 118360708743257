import axios from "axios";
import Swal from "sweetalert2";

const axiosPost = (
  URL,
  data,
  setLoading,
  openDialog,
  openListItem,
  resetFields = [],
  resetArray = []
) => {
  const token = localStorage.getItem("STKN");

  axios
    .post(URL, data, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    })
    .then((response) => {
      setLoading(false);
      openDialog(false);
      openListItem(false);

      Swal.fire({ text: response.data.message, icon: "success" }).then(
        (result) => {
          if (result.isConfirmed) {
            setLoading(false);
          }
        }
      );
      resetFields.map((item) => {
        item("");
      });
      resetArray.map((item) => {
        item([]);
      });
    })
    .catch((error) => {
      openDialog(false);
      setLoading(false);
      openListItem(false);
      Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
    });
};

export default axiosPost;
