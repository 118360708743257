import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useMemo } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import Iconify from "../../../components/Iconify";
const { useState, useRef } = React;

const GetUserLocation = ({ setLatitude, setLongitude }) => {
  const [markerPos, setMarkerPos] = useState({
    lat: 35.52405358959965,
    lng: 35.79139709472657,
  });

  const markerRef = useRef();

  const ref = useRef(null);

  const [openMap, setOpenMap] = useState(false);

  const handleClickOpenMap = () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setMarkerPos(marker.getLatLng());
          setLatitude(marker.getLatLng().lat);
          setLongitude(marker.getLatLng().lng);
        }
      },
    }),
    []
  );
  return (
    <>
      <IconButton
        sx={{
          fontSize: "1rem",
          "&:hover": { background: "none", color: "#B78103" },
        }}
        ref={ref}
        onClick={handleClickOpenMap}
      >
        <Iconify
          icon="material-symbols:map-outline-sharp"
          width={20}
          height={20}
        />
        <span>اختر موقع المستخدم من الخريطة</span>
      </IconButton>

      <Dialog
        disableEscapeKeyDown
        open={openMap}
        onClose={handleCloseMap}
        fullWidth
      >
        <DialogTitle>اختر موقع المستخدم</DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <Box id="map">
            <MapContainer
              zoom={12}
              center={[35.52405358959965, 35.79139709472657]}
              style={{ height: 350 }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={[markerPos.lat, markerPos.lng]}
                draggable={true}
                eventHandlers={eventHandlers}
                // onDragend={updatePosition}
                ref={markerRef}
              />
            </MapContainer>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleCloseMap}>تم</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GetUserLocation;
