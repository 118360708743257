import { filter } from "lodash";
import { myStoresUrl, storageUrl, storesUrl } from "../constants/urls";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  LinearProgress,
  Avatar,
  CircularProgress,
} from "@mui/material";

// components

import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { useTranslation } from "react-i18next";
import axiosGet from "../utils/axiosGet";
import UserMap from "../sections/@dashboard/user/UserMap";
import MyStoreMoreMenu from "../sections/@dashboard/stores/MyStoreMoreMenu";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function MyStore() {
  const { t, i18n } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("SRS"));
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");

  const [loadingUserBtn, setLoadingUserBtn] = useState(false);

  let navigate = useNavigate();
  const token = localStorage.getItem("STKN");
  const [storesList, setStoresList] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    token === null && navigate("/");
    axiosGet(myStoresUrl, setStoresList, setIsLoading, setError);
  }, [token, loadingUserBtn]);

  const TABLE_HEAD = [
    {
      id: "store_name",
      label: "اسم المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "store_city",
      label: "المدينة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "location",
      label: "عنوان المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "phone_number",
      label: "رقم هاتف المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "payment",
      label: "طرق الدفع",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "card_discount",
      label: "نسبة خصم البطاقة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "facebook",
      label: "رابط فيس بوك",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "instagram",
      label: "رابط انستاغرام",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "email",
      label: "البريد الالكتروني",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "logo",
      label: "شعار المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    { id: "" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return isLoading ? (
    <LinearProgress />
  ) : (
    <Page title="لوحة التحكم | متجري">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            معلومات المتجر
          </Typography>
        </Stack>

        <Card>
          {error ? (
            <Box sx={{ padding: "2rem", color: "red" }}>
              {error.data.message === ""
                ? "حدث خطأ ما يرجى التواصل مع فريق الدعم"
                : error.data.message}
            </Box>
          ) : (
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={storesList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      <TableRow hover tabIndex={-1}>
                        <TableCell
                          component="th"
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{
                              margin:
                                i18n.dir() === "rtl" && "0 0.5rem !important",
                            }}
                          >
                            {storesList.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          {storesList.city}
                        </TableCell>
                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          <UserMap address={storesList.location} />
                        </TableCell>
                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          {storesList.phone_number}
                        </TableCell>
                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          {storesList?.payment?.join(", ")}
                        </TableCell>

                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          {storesList?.card_discount
                            ? storesList?.card_discount
                            : "لا يوجد"}
                        </TableCell>

                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          {storesList.facebook}
                        </TableCell>

                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          {storesList.instagram
                            ? storesList.instagram
                            : "لا يوجد"}
                        </TableCell>

                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          {storesList.email ? storesList.email : "لايوجد"}
                        </TableCell>

                        <TableCell component="th" scope="storesList">
                          <Avatar
                            src={`${storageUrl}${storesList.image}`}
                            alt=""
                            sx={{
                              width: 50,
                              height: 50,
                            }}
                          />
                        </TableCell>
                        {/* {roles.includes("user-show") && ( */}
                        <TableCell
                          align={i18n.dir() === "ltr" ? "right" : "left"}
                        >
                          {isLoading ? (
                            <CircularProgress />
                          ) : (
                            <MyStoreMoreMenu
                              user={storesList}
                              token={token}
                              loadingUserBtn={loadingUserBtn}
                              setLoadingUserBtn={setLoadingUserBtn}
                              roles={roles}
                            />
                          )}
                        </TableCell>
                        {/* )} */}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
