import { useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Select,
  OutlinedInput,
  Checkbox,
  InputLabel,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { RolesUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// ----------------------------------------------------------------------

export default function RolesMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
  permissionsList,
  isLoadingPermissions,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Delete Role
  */
  const [openDeleteRole, setOpenDeleteRole] = useState(false);

  const handleClickopenDeleteRole = () => {
    setOpenDeleteRole(true);
  };

  const handleCloseDeleteRole = () => {
    setOpenDeleteRole(false);
  };

  const handleSubmitDeleteRole = () => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${RolesUrl}/${user.id}`,
      setLoadingUserBtn,
      setOpenDeleteRole,
      setOpenDeleteRole
    );
  };

  /* 
     Update Role
  */

  const [openEditRole, setOpenEditRole] = useState(false);
  const [Username, setUsername] = useState(user.name);
  const [permissionsArray, setPermissionsArray] = useState(user.permissions);
  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleChangePermissionsArray = (e) => {
    const {
      target: { value },
    } = e;
    setPermissionsArray(typeof value === "string" ? value.split(",") : value);
  };
  const handleClickopenEditRole = () => {
    setOpenEditRole(true);
  };

  const handleCloseEditRole = () => {
    setOpenEditRole(false);
  };
  const [empty, setEmpty] = useState([]);

  const handleSubmitEditRole = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    Username !== user.name && formData.append("name", Username);
    if (permissionsArray !== user.permissions) {
      for (var i = 0; i < permissionsArray.length; i++) {
        formData.append("permissions[]", permissionsArray[i]);
      }
    }

    axiosPost(
      `${RolesUrl}/${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditRole,
      setIsOpen,
      [setEmpty],
      [setEmpty]
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Delete user */}
        {roles.includes("role-delete") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenDeleteRole}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف الصلاحية"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openDeleteRole}
          onClose={handleCloseDeleteRole}
        >
          <DialogTitle>حذف الصلاحية</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف هذه الصلاحية ؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteRole}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteRole}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        {roles.includes("role-edit") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenEditRole}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل الصلاحية"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openEditRole}
          onClose={handleCloseEditRole}
        >
          <DialogTitle>تعديل الصلاحية</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="اسم الصلاحية"
                  variant="outlined"
                  onChange={handleChangeUsername}
                  value={Username}
                />
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350, maxWidth: 350 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  الصلاحيات
                </InputLabel>

                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={permissionsArray}
                  onChange={handleChangePermissionsArray}
                  input={<OutlinedInput label="الصلاحيات" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {permissionsList.map((item) => (
                    <MenuItem value={item.name} key={item.id}>
                      <Checkbox
                        checked={permissionsArray?.indexOf(item.name) > -1}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditRole}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditRole}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
