import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/lab";
import { ar } from "date-fns/locale";
const DatePicker = ({ title, value, setValue, disablePast }) => {
  const handleChange = (newValue) => {
    setValue(newValue.toISOString().slice(0, 10).replaceAll("/", "-"));
  };

  return (
    <LocalizationProvider locale={ar} dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        disablePast={disablePast}
        label={title}
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField placeholder="YYYY-MM-dd" {...params} />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
