import { useEffect, useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Avatar,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { storageUrl, storesUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";
import GetUserLocationEdit from "../user/GetUserLocationEdit";

// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MyStoreMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Update User
  */
  const [openEditStore, setOpenEditStore] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [storeCity, setStoreCity] = useState("");
  const [storeLatitude, setStoreLatitude] = useState("");
  const [storeLongitude, setStoreLongitude] = useState("");
  const [storePhoneNumber, setStorePhoneNumber] = useState("");
  const [storeFacebook, setStoreFacebook] = useState("");
  const [storeInsta, setStoreInsta] = useState("");
  const [storeEmail, setStoreEmail] = useState("");
  const [storePayment, setStorePayment] = useState([]);
  const [storeCardDiscount, setStoreCardDiscount] = useState("");
  const [StoreLogoToShow, setStoreLogoToShow] = useState("");
  const [previewStoreLogo, setPreviewStoreLogo] = useState(null);
  const [StoreLogoToUpload, setStoreLogoToUpload] = useState(null);
  useEffect(() => {
    if (user !== {}) {
      setStoreName(user?.name);
      setStoreCity(user?.city);
      setStorePayment(user?.payment);
      setStoreCardDiscount(user?.card_discount);
      setStoreLongitude(user?.location?.longitude);
      setStoreLatitude(user?.location?.latitude);
      setStorePhoneNumber(user?.phone_number);
      setStoreFacebook(user?.facebook);
      setStoreInsta(user?.instagram);
      setStoreEmail(user?.email);
      setStoreLogoToShow(user?.image);
    }
  }, [user]);

  const handleChangeStoreName = (event) => {
    setStoreName(event.target.value);
  };

  const handleChangeStoreCity = (event) => {
    setStoreCity(event.target.value);
  };

  const handleChangeStorePhoneNumber = (event) => {
    setStorePhoneNumber(event.target.value);
  };

  const handleChangeStoreFacebook = (event) => {
    setStoreFacebook(event.target.value);
  };

  const handleChangeStoreInsta = (event) => {
    setStoreInsta(event.target.value);
  };

  const handleChangeStoreEmail = (event) => {
    setStoreEmail(event.target.value);
  };

  const handleChangeStoreCardDiscount = (event) => {
    setStoreCardDiscount(event.target.value);
  };

  const handleChangePaymentArray = (e) => {
    const {
      target: { value },
    } = e;
    setStorePayment(typeof value === "string" ? value.split(",") : value);
  };

  const handleClickOpenEditStore = () => {
    setOpenEditStore(true);
  };

  const handleCloseEditStore = () => {
    setOpenEditStore(false);
  };

  const handleCaptureStoreLogo = (e) => {
    setStoreLogoToShow(null);
    setStoreLogoToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewStoreLogo(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [empty, setEmpty] = useState([]);

  const handleSubmitEditStore = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    storeName !== user.name && formData.append("name", storeName);
    storeCity !== user.city && formData.append("city", storeCity);
    storePhoneNumber !== user.phone_number &&
      formData.append("phone_number", storePhoneNumber);
    storeFacebook !== user.facebook &&
      formData.append("facebook", storeFacebook);
    storeInsta !== user.instagram && formData.append("instagram", storeInsta);
    storeEmail !== user.email && formData.append("email", storeEmail);
    storeCardDiscount !== user.card_discount &&
      formData.append("card_discount", storeCardDiscount);
    formData.append("location", `${storeLatitude}, ${storeLongitude}`);
    StoreLogoToUpload !== null && formData.append("image", StoreLogoToUpload);
    if (storePayment !== user.payment) {
      formData.append("payment_getway", storePayment);
    }
    axiosPost(
      `${storesUrl}/${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditStore,
      setIsOpen,
      [setEmpty],
      [setEmpty]
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Edit user */}

        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={handleClickOpenEditStore}
        >
          <ListItemIcon sx={{ marginRight: 0 }}>
            <Iconify icon="eva:edit-2-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="تعديل بيانات متجري"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <Dialog
          disableEscapeKeyDown
          open={openEditStore}
          onClose={handleCloseEditStore}
        >
          <DialogTitle>تعديل بيانات المتجر</DialogTitle>
          <DialogContent sx={{ minWidth: 450 }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  id="outlined-basic"
                  label="اسم المتجر"
                  variant="outlined"
                  onChange={handleChangeStoreName}
                  value={storeName}
                />
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel id="demo-dialog-select-label">المحافظة</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={storeCity}
                  onChange={handleChangeStoreCity}
                  input={<OutlinedInput label="المحافظة" />}
                >
                  <MenuItem value={"Tartous"}>طرطوس</MenuItem>
                  <MenuItem value={"Lattakia"}>اللاذقية</MenuItem>
                  <MenuItem value={"Damascus"}>دمشق</MenuItem>
                  <MenuItem value={"Damascus_countryside"}>ريف دمشق</MenuItem>
                  <MenuItem value={"Aleppo"}>حلب</MenuItem>
                  <MenuItem value={"Homs"}>حمص</MenuItem>
                  <MenuItem value={"Hama"}>حماه</MenuItem>
                  <MenuItem value={"Idlib"}>ادلب</MenuItem>
                  <MenuItem value={"Suwayda"}>السويداء</MenuItem>
                  <MenuItem value={"Daraa"}>درعا</MenuItem>
                  <MenuItem value={"Quneitra"}>القنيطرة</MenuItem>
                  <MenuItem value={"Deir_Ezzor"}>دير الزور</MenuItem>
                  <MenuItem value={"Al_Hasakah"}>الحسكة</MenuItem>
                  <MenuItem value={"Raqqa"}>الرقة</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="خط العرض"
                  variant="outlined"
                  value={storeLatitude}
                  disabled
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="خط الطول"
                  variant="outlined"
                  value={storeLongitude}
                  disabled
                />
              </FormControl>
            </Box>
            <GetUserLocationEdit
              longitude={storeLongitude}
              latitude={storeLatitude}
              setLatitude={setStoreLatitude}
              setLongitude={setStoreLongitude}
            />

            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="رقم الهاتف"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangeStorePhoneNumber}
                  value={storePhoneNumber}
                />
              </FormControl>
            </Box>

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="قسمة خصم البطاقة"
                  variant="outlined"
                  onChange={handleChangeStoreCardDiscount}
                  value={storeCardDiscount}
                />
              </FormControl>
            </Box>

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="حساب فيس بوك للمتجر"
                  variant="outlined"
                  onChange={handleChangeStoreFacebook}
                  value={storeFacebook}
                />
              </FormControl>
            </Box>

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="حساب انستاغرام للمتجر"
                  variant="outlined"
                  onChange={handleChangeStoreInsta}
                  value={storeInsta}
                />
              </FormControl>
            </Box>

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="البريد الالكتروني للمتجر"
                  variant="outlined"
                  onChange={handleChangeStoreEmail}
                  value={storeEmail}
                />
              </FormControl>
            </Box>

            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel id="demo-dialog-select-label">طرق الدفع</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={storePayment}
                  multiple
                  onChange={handleChangePaymentArray}
                  input={<OutlinedInput label="طرق الدفع" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"cach"} key={"cach"}>
                    <Checkbox checked={storePayment?.indexOf("cach") > -1} />
                    <ListItemText primary={"cach"} />
                  </MenuItem>
                  <MenuItem value={"syriatel"} key={"syriatel"}>
                    <Checkbox
                      checked={storePayment?.indexOf("syriatel") > -1}
                    />
                    <ListItemText primary={"syriatel"} />
                  </MenuItem>
                  <MenuItem value={"mtn"} key={"mtn"}>
                    <Checkbox checked={storePayment?.indexOf("mtn") > -1} />
                    <ListItemText primary={"mtn"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>صورة شعار المتجر</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewStoreLogo ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewStoreLogo}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewStoreLogo(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      ارفع صورة شعار المتجر
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureStoreLogo}
                      />
                    </Button>
                  )}
                  {StoreLogoToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${StoreLogoToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setStoreLogoToShow(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditStore}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditStore}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
