import { Navigate, Routes, Route } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import User from "./pages/User";
import NotFound from "./pages/Page404";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./pages/Unauthorized";
import Brands from "./pages/Brands";
import Categories from "./pages/Categories";
import SubCategories from "./pages/SubCategories";
import Products from "./pages/Products";
import Sliders from "./pages/Sliders";
import Cards from "./pages/Cards";
import Roles from "./pages/Roles";
import PhoneNumbers from "./pages/PhoneNumbers";
import Info from "./pages/Info";
import Orders from "./pages/Orders";
import Rates from "./pages/Rates";
import Complaints from "./pages/Complaints";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Stores from "./pages/Stores";
import MyStore from "./pages/MyStore";
import StoreProducts from "./pages/StoreProducts";

// ----------------------------------------------------------------------

export default function Router() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="login" element={<Login />} />
      <Route path="/" element={<LogoOnlyLayout />}>
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-of-service" element={<TermsOfService />} /> */}
      </Route>

      {/* Protected Routes */}

      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="app" element={<DashboardApp />} />

        <Route element={<RequireAuth allowedRoles={["user-show"]} />}>
          <Route path="users" element={<User />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["Show-Brand"]} />}>
          <Route path="brands" element={<Brands />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["Show-Category"]} />}>
          <Route path="categories" element={<Categories />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["Show-Subcategory"]} />}>
          <Route path="sub-categories" element={<SubCategories />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["Show-Product"]} />}>
          <Route path="products" element={<Products />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["Show-Product"]} />}>
          <Route path="my-products" element={<StoreProducts />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["show-Card"]} />}>
          <Route path="cards" element={<Cards />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["show-slider"]} />}>
          <Route path="sliders" element={<Sliders />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["role-show"]} />}>
          <Route path="roles" element={<Roles />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["show-SupportPhone"]} />}>
          <Route path="phone-numbers" element={<PhoneNumbers />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["show-Info"]} />}>
          <Route path="info" element={<Info />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["Order-show"]} />}>
          <Route path="orders" element={<Orders />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["rate-list"]} />}>
          <Route path="rates" element={<Rates />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["Show-Complaint"]} />}>
          <Route path="complaints" element={<Complaints />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["user-show"]} />}>
          <Route path="stores" element={<Stores />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={["Show-Product"]} />}>
          <Route path="my-store" element={<MyStore />} />
        </Route>
      </Route>

      {/* Catch All */}
      <Route path="*" element={<Navigate to="/404" />} replace />
    </Routes>
  );
}
