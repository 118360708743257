import axios from "axios";
import React from "react";

const axiosGet = (URL, setData, setLoading, setError, setTotalPages) => {
  const token = localStorage.getItem("STKN");
  axios
    .get(URL, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    })
    .then((response) => {
      setLoading(false);
      setData(response.data.data);
      setTotalPages !== undefined &&
        setTotalPages(response.data.meta.last_page);
    })
    .catch((error) => {
      setLoading(false);
      setError(error.response);
    });
};

export default axiosGet;
