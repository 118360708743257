import axios from "axios";
import Swal from "sweetalert2";

const axiosDelete = (URL, setLoading, openDialog, setopen) => {
  const token = localStorage.getItem("STKN");

  axios
    .delete(`${URL}`, {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    })
    .then((response) => {
      setLoading(false);
      openDialog(false);
      setopen(false);
      Swal.fire({ text: response.data.message, icon: "success" }).then(
        (result) => {
          if (result.isConfirmed) {
            setLoading(false);
          }
        }
      );
    })
    .catch((error) => {
      openDialog(false);
      setopen(false);
      setLoading(false);
      Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
    });
};

export default axiosDelete;
