// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography, Box } from "@mui/material";

// components
import Page from "../components/Page";
import { LoginForm } from "../sections/authentication/login";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="تسجيل الدخول | شكرا">
      <SectionStyle sx={{ display: { xs: "none", md: "flex" }, padding: 1 }}>
        <img src="/static/illustrations/illustration_login.png" alt="login" />
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              تسجيل الدخول إلى لوحة تحكم متجر شكراً
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              يرجى إدخال بيانات اعتمادك
            </Typography>
          </Stack>

          <LoginForm />
          {/* <Box
            sx={{
              display: "flex",
              gap: "1rem",
              marginTop: "3rem",
              direction: "ltr",
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "#00305b" }}
              to={"/privacy-policy"}
            >
              Privacy Policy
            </Link>
            <Link
              style={{ textDecoration: "none", color: "#00305b" }}
              to={"/terms-of-service"}
            >
              Terms Of Service
            </Link>
          </Box> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
