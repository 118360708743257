import { useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
//
import Iconify from "./Iconify";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "#495676",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block", bgcolor: "#495676" },
  };

  const activeSubStyle = {
    color: "#495676",
    fontWeight: "fontWeightMedium",
  };
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            direction: "rtl",
          }}
        >
          <ListItemText
            disableTypography
            primary={title}
            sx={{ textAlign: "right" }}
          />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={title}
                    sx={{ textAlign: "right" }}
                  />
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemText
        disableTypography
        primary={title}
        sx={{ textAlign: "right" }}
      />
      {info && info}
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { t } = useTranslation();
  const role_name = localStorage.getItem("SRN");

  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  const sidebarConfig =
    role_name === "SuperAdmin"
      ? [
          {
            title: "الإحصائيات",
            path: "/dashboard/app",
            icon: getIcon("eva:pie-chart-2-fill"),
          },
          {
            title: "المستخدمين",
            path: "/dashboard/users",
            icon: getIcon("ph:users"),
          },
          {
            title: "المتاجر",
            path: "/dashboard/stores",
            icon: getIcon("material-symbols:store-rounded"),
          },
          {
            title: "الصلاحيات",
            path: "/dashboard/roles",
            icon: getIcon("fluent-mdl2:permissions"),
          },
          {
            title: "العلامات التجارية",
            path: "/dashboard/brands",
            icon: getIcon("fluent-mdl2:verified-brand"),
          },
          {
            title: "الفئات الرئيسية",
            path: "/dashboard/categories",
            icon: getIcon("mdi:category-outline"),
          },
          {
            title: "الفئات الفرعية",
            path: "/dashboard/sub-categories",
            icon: getIcon("carbon:category"),
          },
          {
            title: "المنتجات",
            path: "/dashboard/products",
            icon: getIcon("simple-icons:producthunt"),
          },
          {
            title: "البطاقات",
            path: "/dashboard/cards",
            icon: getIcon("tabler:cards"),
          },
          {
            title: "الطلبات",
            path: "/dashboard/orders",
            icon: getIcon("icon-park-outline:transaction-order"),
          },
          {
            title: "السلايدرات",
            path: "/dashboard/sliders",
            icon: getIcon("material-symbols:view-carousel"),
          },
          {
            title: "ارقام المحافظات",
            path: "/dashboard/phone-numbers",
            icon: getIcon("material-symbols:contact-phone"),
          },
          {
            title: "معلومات التطبيق",
            path: "/dashboard/info",
            icon: getIcon("mdi:about-circle-outline"),
          },
          {
            title: "التقييمات",
            path: "/dashboard/rates",
            icon: getIcon("ic:outline-star-rate"),
          },
          {
            title: "شكاوي العملاء",
            path: "/dashboard/complaints",
            icon: getIcon("ri:customer-service-2-fill"),
          },
        ]
      : [
          {
            title: "متجري",
            path: "/dashboard/my-store",
            icon: getIcon("material-symbols:store-rounded"),
          },
          {
            title: "منتجاتي",
            path: "/dashboard/my-products",
            icon: getIcon("simple-icons:producthunt"),
          },
          {
            title: "طلباتي",
            path: "/dashboard/orders",
            icon: getIcon("icon-park-outline:transaction-order"),
          },
        ];
  return (
    <Box {...other} sx={{ paddingBottom: "2rem" }}>
      <List disablePadding>
        {sidebarConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
