// export const apiUrl = "https://thanx.somar-kesen.com/api/";
export const apiUrl = "https://api.shukramart.sy/api/";

// export const storageUrl = "https://thanx.somar-kesen.com/";
export const storageUrl = "https://api.shukramart.sy/";

export const loginUrl = `${apiUrl}dashboard/login`;
export const logOutUrl = `${apiUrl}logout`;

export const getRolesUrl = `${apiUrl}roles`;

// start users Urls
export const userListUrl = `${apiUrl}users`;
export const userCreateUrl = `${apiUrl}users/create`;
export const userNotificationUrl = `${apiUrl}users/notification`;
// end users Urls

// start Brands Urls
export const BrandsUrl = `${apiUrl}brand`;
export const CreateBrandUrl = `${apiUrl}brand/store`;
// end Brands Urls

// start Categories Urls
export const CategoriesUrl = `${apiUrl}category`;
export const CreateCategoryUrl = `${apiUrl}category/store`;
// end Categories Urls

// start SubCategories Urls
export const SubCategoriesUrl = `${apiUrl}subcategory`;
export const SubCreateCategoryUrl = `${apiUrl}subcategory/store`;
// end SubCategories Urls

// start Products Urls
export const ProductsUrl = `${apiUrl}product`;
export const ProductsCreateUrl = `${apiUrl}product/store`;
export const ProductDeletePhotoUrl = `${apiUrl}product/photo`;
export const ProductUpdateUrl = `${apiUrl}product/update`;
// end Products Urls

// start Cards Urls
export const CardsUrl = `${apiUrl}card`;
export const CardsCreateUrl = `${apiUrl}card/store`;
// end Cards Urls

// start Sliders Urls
export const SlidersUrl = `${apiUrl}slider`;
export const SlidersCreateUrl = `${apiUrl}slider/store`;
// end Sliders Urls

// start Roles Urls
export const RolesUrl = `${apiUrl}roles`;
export const RolesCreateUrl = `${apiUrl}roles/create`;
// end Roles Urls

// start Permissions Urls
export const PermissionsUrl = `${apiUrl}roles/permissions`;
// end Permissions Urls

// start Info nfoUrls
export const InfoUrl = `${apiUrl}info`;
export const updateInfoUrl = `${apiUrl}info/update`;
// end Info Urls

// start phoneNumbers nfoUrls
export const phoneNumbersUrl = `${apiUrl}SupportPhone`;
export const addPhoneNumbersUrl = `${apiUrl}SupportPhone/store`;
export const updatePhoneNumbersUrl = `${apiUrl}SupportPhone/update/`;
// end phoneNumbers Urls

// start Orders nfoUrls
export const OrdersUrl = `${apiUrl}order`;
export const addShippingCostUrl = `${apiUrl}order/shipping_cost`;
// end Orders Urls

// start Rates Urls
export const RatesUrl = `${apiUrl}rate`;
// end Rates Urls

// start Complaint Urls
export const ComplaintUrl = `${apiUrl}Complaint`;
// end Complaint Urls

// start charts Urls
export const ChartsUrl = `${apiUrl}charts`;
// end charts Urls

// start stores Urls
export const storesUrl = `${apiUrl}store`;
export const myStoresUrl = `${apiUrl}store/me`;
// end stores Urls

/* Notifications */
export const updateFcmToken = `${apiUrl}notification/update/fcm?_method=PATCH`;
