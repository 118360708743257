import { filter } from "lodash";
import { storageUrl, storesUrl } from "../constants/urls";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  LinearProgress,
  Avatar,
} from "@mui/material";

// components

import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { useTranslation } from "react-i18next";
import axiosGet from "../utils/axiosGet";
import AddNewStore from "../sections/@dashboard/stores/AddNewStore";
import UserMap from "../sections/@dashboard/user/UserMap";
import StoresMoreMenu from "../sections/@dashboard/stores/StoresMoreMenu";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Stores() {
  const { t, i18n } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("SRS"));
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openNewStore, setOpenNewStore] = useState(false);
  const [loadingUserBtn, setLoadingUserBtn] = useState(false);

  let navigate = useNavigate();
  const token = localStorage.getItem("STKN");
  const [storesList, setStoresList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    token === null && navigate("/");
    axiosGet(storesUrl, setStoresList, setIsLoading, setError);
  }, [token, loadingUserBtn]);

  let filteredUsers = applySortFilter(
    storesList,
    getComparator(order, orderBy),
    filterName
  );
  const TABLE_HEAD = [
    {
      id: "store_name",
      label: "اسم المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "user_name",
      label: "اسم مستخدم مالك المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "store_city",
      label: "المدينة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "payment",
      label: "طرق الدفع",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "location",
      label: "عنوان المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "phone_number",
      label: "رقم هاتف المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "card_discount",
      label: "نسبة خصم البطاقة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "facebook",
      label: "رابط فيس بوك",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "instagram",
      label: "رابط انستاغرام",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "email",
      label: "البريد الالكتروني",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "logo",
      label: "شعار المتجر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    { id: "" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = storesList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - storesList.length) : 0;

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const isUserNotFound = filteredUsers.length === 0;

  const handleCloseNewStore = () => {
    setOpenNewStore(false);
  };

  return isLoading ? (
    <LinearProgress />
  ) : (
    <Page title="لوحة التحكم | المتاجر">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            المتاجر
          </Typography>

          <AddNewStore
            roles={roles}
            handleCloseNewStore={handleCloseNewStore}
            loadingUserBtn={loadingUserBtn}
            setLoadingUserBtn={setLoadingUserBtn}
          />
        </Stack>

        <Card>
          {error ? (
            <Box sx={{ padding: "2rem", color: "red" }}>
              {error.data.message === ""
                ? "حدث خطأ ما يرجى التواصل مع فريق الدعم"
                : error.data.message}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <UserListToolbar
                  placeHolder="ابحث هنا..."
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Box>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={storesList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const { id, name } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <Typography
                                  variant="subtitle2"
                                  wrap
                                  sx={{
                                    margin:
                                      i18n.dir() === "rtl" &&
                                      "0 0.5rem !important",
                                  }}
                                >
                                  {name}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.user.name}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.city}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.payment ? row.payment.join(", ") : ""}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <UserMap address={row.location} />
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.phone_number}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row?.card_discount}
                              </TableCell>

                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <Typography
                                  sx={{
                                    wordBreak: "break-all",
                                  }}
                                  variant="subtitle2"
                                  noWrap
                                >
                                  <a href={row.facebook} target="_blank">
                                    {row?.facebook?.slice(0, 25) + "..."}
                                  </a>
                                </Typography>
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <a href={row?.instagram} target="_blank">
                                  {row.instagram
                                    ? row?.instagram?.slice(0, 25) + "..."
                                    : "لايوجد"}
                                </a>
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.email ? row.email : "لايوجد"}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Avatar
                                  src={`${storageUrl}${row.image}`}
                                  alt=""
                                  sx={{
                                    width: 50,
                                    height: 50,
                                  }}
                                />
                              </TableCell>
                              {/* {roles.includes("user-show") && ( */}
                              <TableCell
                                align={i18n.dir() === "ltr" ? "right" : "left"}
                              >
                                <StoresMoreMenu
                                  user={row}
                                  token={token}
                                  loadingUserBtn={loadingUserBtn}
                                  setLoadingUserBtn={setLoadingUserBtn}
                                  roles={roles}
                                />
                              </TableCell>
                              {/* )} */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                sx={{ direction: "ltr" }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={storesList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("description.UsersPageLabelRowsPerPage")}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
