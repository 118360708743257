import { useEffect, useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  Divider,
  Avatar,
  Typography,
} from "@mui/material";
// component
import ImageUploading from "react-images-uploading";

import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import {
  ProductDeletePhotoUrl,
  ProductsUrl,
  ProductUpdateUrl,
  storageUrl,
} from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";

// ----------------------------------------------------------------------

export default function MyStoreProductsMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
  brandsList,
  categoriesList,
  isLoadingBrands,
  isLoadingCategories,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  /* 
     Delete user
  */
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);

  const handleClickopenDeleteProduct = () => {
    setOpenDeleteProduct(true);
  };

  const handleCloseDeleteProduct = () => {
    setOpenDeleteProduct(false);
  };

  const handleSubmitDeleteProduct = () => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${ProductsUrl}/${user.id}`,
      setLoadingUserBtn,
      setOpenDeleteProduct
    );
  };

  /* 
     Update User
  */

  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [openDeleteProductImage, setOpenDeleteProductImage] = useState(false);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  useEffect(() => {
    setSubCategoriesList(
      categoriesList.filter((item) => item.id == user.category.id)
    );
  }, [categoriesList]);

  const [Username, setUsername] = useState(user.name);
  const [description, setDescription] = useState(user.description);
  const [price, setPrice] = useState(user.price);
  const [discountPrice, setDiscountPrice] = useState(user.discount_price);
  const [brandId, setBrandId] = useState(user.brand.id);
  const [categoryId, setCategoryId] = useState(user.category.id);
  const [subCategoryId, setSubCategoryId] = useState(user.subcategory.id);

  const [productImageToShow, setProductImageToShow] = useState(
    user.product_photo
  );
  const [previewProductImage, setPreviewProductImage] = useState(null);
  const [productImageToUpload, setProductImageToUpload] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesToShow, setImagesToShow] = useState(user.photo_gallery);
  const maxNumber = 69;

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangePrice = (event) => {
    setPrice(event.target.value);
  };

  const handleChangeDiscountPrice = (event) => {
    setDiscountPrice(event.target.value);
  };

  const handleChangeBrandId = (event) => {
    setBrandId(event.target.value);
  };

  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
  };

  const handleChangeCategoryId = (event) => {
    setCategoryId(event.target.value);
    setSubCategoriesList(
      categoriesList.filter((item) => item.id === event.target.value)
    );
  };

  const handleChangeSubCategoryId = (event) => {
    setSubCategoryId(event.target.value);
  };

  const handleCaptureProductImage = (e) => {
    setProductImageToShow(null);
    setProductImageToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewProductImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleClickopenEditProduct = () => {
    setOpenEditProduct(true);
  };

  const handleCloseEditProduct = () => {
    setOpenEditProduct(false);
  };

  const handleCloseDeleteProductImage = () => {
    setOpenDeleteProductImage(false);
  };

  const handleOpenDeleteProductImage = () => {
    setOpenDeleteProductImage(true);
  };

  const handleDeleteimage = (id) => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${ProductDeletePhotoUrl}/${id}`,
      setLoadingUserBtn,
      setOpenDeleteProductImage,
      setOpenEditProduct
    );
  };

  const handleSubmitEditCategory = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    Username !== user.name && formData.append("name", Username);
    description !== user.description &&
      formData.append("description", description);
    price !== user.price && formData.append("price", price);
    discountPrice !== user.discount_price &&
      formData.append("discount_price", discountPrice);
    brandId !== user.brand.id && formData.append("brand_id", brandId);
    categoryId !== user.category.id &&
      formData.append("category_id", categoryId);
    subCategoryId !== user.subcategory.id &&
      formData.append("subcategory_id", subCategoryId);

    images.length > 0 &&
      images.map((item, i) => {
        formData.append(`images[${i}]`, item.file);
      });
    productImageToUpload !== null &&
      formData.append("product_photo", productImageToUpload);

    axiosPost(
      `${ProductUpdateUrl}/${user.id}`,
      formData,
      setLoadingUserBtn,
      setOpenEditProduct,
      setIsOpen
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Delete user */}
        {roles.includes("Delete-Product") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenDeleteProduct}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف المنتج"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openDeleteProduct}
          onClose={handleCloseDeleteProduct}
        >
          <DialogTitle>حذف المنتج</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف هذا المنتج؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteProduct}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteProduct}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        {roles.includes("Update-Product") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenEditProduct}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل المنتج"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          fullScreen
          disableEscapeKeyDown
          open={openEditProduct}
          onClose={handleCloseEditProduct}
        >
          <DialogTitle>تعديل المنتج</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="اسم المنتج"
                  variant="outlined"
                  onChange={handleChangeUsername}
                  value={Username}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="الوصف"
                  variant="outlined"
                  multiline
                  rows={2}
                  onChange={handleChangeDescription}
                  value={description}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="السعر"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangePrice}
                  value={price}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="سعر التخفيض"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangeDiscountPrice}
                  value={discountPrice}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  العلامة التجارية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={brandId}
                  onChange={handleChangeBrandId}
                  input={<OutlinedInput label="العلامة التجارية" />}
                >
                  {!isLoadingBrands
                    ? brandsList.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  الفئة الرئيسية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={categoryId}
                  onChange={handleChangeCategoryId}
                  input={<OutlinedInput label="الفئة الرئيسية" />}
                >
                  {!isLoadingCategories
                    ? categoriesList.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  الفئة الفرعية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={subCategoryId}
                  onChange={handleChangeSubCategoryId}
                  input={<OutlinedInput label="الفئة الفرعية" />}
                >
                  {subCategoriesList.length > 0 ? (
                    subCategoriesList[0].subcategorys.length > 0 ? (
                      subCategoriesList[0].subcategorys.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""} disabled>
                        لا يوجد فئات فرعية
                      </MenuItem>
                    )
                  ) : null}
                </Select>
              </FormControl>
            </Box>
            <Divider sx={{ margin: "1rem 0" }} />
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>صورة المنتج الرئيسية</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewProductImage ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewProductImage}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewProductImage(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      ارفع صورة المنتج الرئيسية
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureProductImage}
                      />
                    </Button>
                  )}
                  {productImageToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${productImageToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setProductImageToShow(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
            <Divider sx={{ margin: "1rem 0" }} />
            <Typography sx={{ marginBottom: "1rem" }}>
              معرض صور المنتجات
            </Typography>
            <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
              {imagesToShow.map((image, index) => (
                <Box
                  key={index}
                  className="image-item"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Avatar
                    src={`${storageUrl}${image.path}`}
                    alt=""
                    sx={{ width: 200, height: 200 }}
                  />
                  <div className="image-item__btn-wrapper">
                    <Button
                      sx={{ margin: "0 0.5rem" }}
                      variant={"outlined"}
                      onClick={() => handleDeleteimage(image.id)}
                    >
                      حذف
                    </Button>
                  </div>
                  {/* <Dialog
                    disableEscapeKeyDown
                    open={openDeleteProductImage}
                    onClose={handleCloseDeleteProductImage}
                  >
                    <DialogTitle>حذف الصورة</DialogTitle>
                    <DialogContent sx={{ width: "100%" }}>
                      هل أنت متأكد من حذف هذه الصورة؟
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                      <Button onClick={handleCloseDeleteProductImage}>
                        {t("description.Cancel")}
                      </Button>
                      <LoadingButton
                        loading={loadingUserBtn}
                        onClick={() => handleDeleteimage(image.id)}
                      >
                        {t("description.Ok")}
                      </LoadingButton>
                    </DialogActions>
                  </Dialog> */}
                </Box>
              ))}
            </Box>
            <Divider sx={{ margin: "1rem 0" }} />

            <Typography sx={{ marginBottom: "1rem" }}>
              إضافة صور جديدة إلى المعرض
            </Typography>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <Button
                    variant={"outlined"}
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    انقر او اسحب هنا
                  </Button>
                  &nbsp;
                  <Button variant={"outlined"} onClick={onImageRemoveAll}>
                    مسح جميع الصور
                  </Button>
                  <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                    {imageList.map((image, index) => (
                      <Box
                        key={index}
                        className="image-item"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        {/* <img src={image["data_url"]} alt="" width="100" /> */}
                        <Avatar
                          src={image["data_url"]}
                          alt=""
                          sx={{ width: 200, height: 200 }}
                        />
                        <div className="image-item__btn-wrapper">
                          <Button
                            variant={"outlined"}
                            onClick={() => onImageUpdate(index)}
                          >
                            تعديل
                          </Button>
                          <Button
                            sx={{ margin: "0 0.5rem" }}
                            variant={"outlined"}
                            onClick={() => onImageRemove(index)}
                          >
                            حذف
                          </Button>
                        </div>
                      </Box>
                    ))}
                  </Box>
                </div>
              )}
            </ImageUploading>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditProduct}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditCategory}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
