import { filter } from "lodash";
import { RatesUrl } from "../constants/urls";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Box,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
} from "@mui/material";

// components

import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { useTranslation } from "react-i18next";
import axiosGet from "../utils/axiosGet";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Rates() {
  const { t, i18n } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("SRS"));
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loadingUserBtn, setLoadingUserBtn] = useState(false);

  let navigate = useNavigate();
  const token = localStorage.getItem("STKN");
  const [RatesList, setRatesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    token === null && navigate("/");
    axiosGet(RatesUrl, setRatesList, setIsLoading, setError);
  }, [token, loadingUserBtn]);

  let filteredUsers = applySortFilter(
    RatesList,
    getComparator(order, orderBy),
    filterName
  );
  const TABLE_HEAD = [
    {
      id: "name",
      label: "اسم المستخدم",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "product",
      label: "المنتج",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "brand",
      label: "العلامة التجارية",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "category",
      label: "الفئة الرئيسية",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "subcategory",
      label: "الفئة الفرعية",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "rate",
      label: "التقييم",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "text",
      label: "الملاحظة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = RatesList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - RatesList.length) : 0;

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) =>
          _user.user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _user.product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const isUserNotFound = filteredUsers.length === 0;

  return isLoading ? (
    <LinearProgress />
  ) : (
    <Page title="لوحة التحكم | التقييمات">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            التقييمات
          </Typography>
        </Stack>

        <Card>
          {error ? (
            <Box sx={{ padding: "2rem", color: "red" }}>
              {error.data.message === ""
                ? "حدث خطأ ما يرجى التواصل مع فريق الدعم"
                : error.data.message}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <UserListToolbar
                  placeHolder="ابحث هنا..."
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Box>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={RatesList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const { id, name } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell component="th" scope="row">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      margin:
                                        i18n.dir() === "rtl" &&
                                        "0 0.5rem !important",
                                    }}
                                  >
                                    {row.user.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.product.name}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.product.brand.name}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.product.category.name}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.product.subcategory.name}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.rate}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.text}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                sx={{ direction: "ltr" }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={RatesList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("description.UsersPageLabelRowsPerPage")}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
