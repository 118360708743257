import { useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { CardsUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";
import DatePicker from "../../../components/DatePicker";

// ----------------------------------------------------------------------

export default function CardsMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Delete user
  */
  const [openDeleteCard, setOpenDeleteCard] = useState(false);

  const handleClickopenDeleteCard = () => {
    setOpenDeleteCard(true);
  };

  const handleCloseDeleteCard = () => {
    setOpenDeleteCard(false);
  };

  const handleSubmitDeleteCard = () => {
    setLoadingUserBtn(true);
    axiosDelete(`${CardsUrl}/${user.id}`, setLoadingUserBtn, setOpenDeleteCard);
  };

  /* 
     Update User
  */

  const [openEditCard, setOpenEditCard] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number);
  const [cardKey, setCardkey] = useState(user.card_key);
  const [expiryAt, setExpiryAt] = useState(user.expiry_at);

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangeCardKey = (event) => {
    setCardkey(event.target.value);
  };

  const handleClickOpenEditCard = () => {
    setOpenEditCard(true);
  };

  const handleCloseEditCard = () => {
    setOpenEditCard(false);
  };

  const handleSubmitEditCard = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    phoneNumber !== user.phone_number &&
      formData.append("phone_number", phoneNumber);
    cardKey !== user.card_key && formData.append("card_key", cardKey);
    expiryAt !== user.expiry_at && formData.append("expiry_at", expiryAt);
    axiosPost(
      `${CardsUrl}/${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditCard,
      setIsOpen
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Delete user */}
        {roles.includes("Delete-Card") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenDeleteCard}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف البطاقة"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openDeleteCard}
          onClose={handleCloseDeleteCard}
        >
          <DialogTitle>حذف البطاقة</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف هذه البطاقة؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleCloseDeleteCard}
              variant={"outlined"}
              sx={{ margin: "0 1rem" }}
            >
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              variant={"outlined"}
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteCard}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        {roles.includes("Update-Card") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditCard}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل البطاقة"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openEditCard}
          onClose={handleCloseEditCard}
        >
          <DialogTitle>تعديل البطاقة</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="رقم الهاتف"
                  type={"number"}
                  variant="outlined"
                  onChange={handleChangePhoneNumber}
                  value={phoneNumber}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="رقم البطاقة"
                  variant="outlined"
                  onChange={handleChangeCardKey}
                  value={cardKey}
                />
              </FormControl>
            </Box>

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <DatePicker
                  title={"تاريخ انتهاء الصلاحية"}
                  setValue={setExpiryAt}
                  value={expiryAt}
                  disablePast={true}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditCard}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditCard}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
