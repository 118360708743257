import { useEffect,useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Avatar,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Box,
  LinearProgress,
  Divider,
  Pagination,
} from "@mui/material";
import ImageUploading from "react-images-uploading";
// component
import Iconify from "../../../components/Iconify";


import { useTranslation } from "react-i18next";
import { 
  storageUrl,
  storesUrl,
  BrandsUrl,
  CategoriesUrl,
  ProductsCreateUrl,  
  } from "../../../constants/urls";

import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";
import axiosGet from "../../../utils/axiosGet";

import GetUserLocationEdit from "../user/GetUserLocationEdit";

// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function StoresMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Delete user
  */
 /*
  Starting here 
 */
  const [openDeleteStore, setOpenDeleteStore] = useState(false);
  const [openNewProduct, setOpenNewProduct] = useState(false);
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [brandId, setBrandId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [productImageToShow, setProductImageToShow] = useState("");
  const [previewProductImage, setPreviewProductImage] = useState(null);
  const [productImageToUpload, setProductImageToUpload] = useState(null);
  const [brandsList, setBrandsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBrands, setIsLoadingBrands] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingStores, setIsLoadingStores] = useState(true);
  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const handleSubmitAddProduct = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    formData.append("name", productName);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("discount_price", discountPrice);
    formData.append("brand_id", brandId);
    formData.append("category_id", categoryId);
    formData.append("subcategory_id", subCategoryId);
    formData.append("store_id", storeId);

    images.length > 0 &&
      images.map((item, i) => {
        formData.append(`images[${i}]`, item.file);
      });
    formData.append("product_photo", productImageToUpload);

    axiosPost(
      ProductsCreateUrl,
      formData,
      setLoadingUserBtn,
      setOpenNewProduct,
      setOpenNewProduct,
      [
        setProductName,
        setProductImageToShow,
        setProductImageToUpload,
        setPreviewProductImage,
        setDescription,
        setPrice,
        setBrandId,
        setDiscountPrice,
        setCategoryId,
        setSubCategoryId,
        setImages,
      ]
    );
  };
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  const token = localStorage.getItem("STKN");
  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
  };
  const handleChangeProductName = (event) => {
    setProductName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangePrice = (event) => {
    setPrice(event.target.value);
  };

  const handleChangeDiscountPrice = (event) => {
    setDiscountPrice(event.target.value);
  };

  const handleChangeBrandId = (event) => {
    setBrandId(event.target.value);
  };

  const handleChangeCategoryId = (event) => {
    setCategoryId(event.target.value);
    setSubCategoriesList(
      categoriesList.filter((item) => item.id === event.target.value)
    );
  };

  const handleChangeSubCategoryId = (event) => {
    setSubCategoryId(event.target.value);
  };

  const handleChangeStoreId = (event) => {
    setStoreId(event.target.value);
  };

  const handleCaptureProductImage = (e) => {
    setProductImageToShow(null);
    setProductImageToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewProductImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const handleClickOpenNewProduct = () => {
    setOpenNewProduct(true);
    setStoreId(user.id);
    setStoreName(user.name);
  };
  const handleCloseNewProduct = () => {
    setOpenNewProduct(false);
    setStoreId();
  };
  useEffect(() => {
    token === null && navigate("/");
    axiosGet(BrandsUrl, setBrandsList, setIsLoadingBrands, setError);
    axiosGet(
      CategoriesUrl,
      setCategoriesList,
      setIsLoadingCategories,
      setError
    );
  }, []);




  const handleClickopenDeleteStore = () => {
    setOpenDeleteStore(true);
  };
  const handleCloseDeleteStore = () => {
    setOpenDeleteStore(false);
  };

  const handleSubmitDeleteStore = () => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${storesUrl}/${user.id}`,
      setLoadingUserBtn,
      setOpenDeleteStore,
      setOpenDeleteStore
    );
  };

  /* 
     Update User
  */

  const [openEditStore, setOpenEditStore] = useState(false);
  const [storeName, setStoreName] = useState(user.name);
  const [storeCity, setStoreCity] = useState(user.city);
  const [storeLatitude, setStoreLatitude] = useState(user.location.latitude);
  const [storeLongitude, setStoreLongitude] = useState(user.location.longitude);
  const [storePhoneNumber, setStorePhoneNumber] = useState(user.phone_number);
  const [storeFacebook, setStoreFacebook] = useState(user.facebook);
  const [storePayment, setStorePayment] = useState(user.payment);
  const [StoreLogoToShow, setStoreLogoToShow] = useState(user.image);
  const [previewStoreLogo, setPreviewStoreLogo] = useState(null);
  const [StoreLogoToUpload, setStoreLogoToUpload] = useState(null);
  const [storeInsta, setStoreInsta] = useState(user.instagram);
  const [storeEmail, setStoreEmail] = useState(user.email);
  const [storeCardDiscount, setStoreCardDiscount] = useState(
    user.card_discount
  );
  const handleChangeStoreName = (event) => {
    setStoreName(event.target.value);
  };

  const handleChangeStoreCity = (event) => {
    setStoreCity(event.target.value);
  };

  const handleChangeStorePhoneNumber = (event) => {
    setStorePhoneNumber(event.target.value);
  };

  const handleChangeStoreFacebook = (event) => {
    setStoreFacebook(event.target.value);
  };

  const handleChangePaymentArray = (e) => {
    const {
      target: { value },
    } = e;
    setStorePayment(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeStoreInsta = (event) => {
    setStoreInsta(event.target.value);
  };

  const handleChangeStoreEmail = (event) => {
    setStoreEmail(event.target.value);
  };

  const handleChangeStoreCardDiscount = (event) => {
    setStoreCardDiscount(event.target.value);
  };

  const handleClickOpenEditStore = () => {
    setOpenEditStore(true);
  };

  const handleCloseEditStore = () => {
    setOpenEditStore(false);
  };

  const handleCaptureStoreLogo = (e) => {
    setStoreLogoToShow(null);
    setStoreLogoToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewStoreLogo(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [empty, setEmpty] = useState([]);

  const handleSubmitEditStore = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    storeName !== user.name && formData.append("name", storeName);
    storeCity !== user.city && formData.append("city", storeCity);
    storePhoneNumber !== user.phone_number &&
      formData.append("phone_number", storePhoneNumber);
    storeFacebook !== user.facebook &&
      formData.append("facebook", storeFacebook);

    storeInsta !== user.instagram && formData.append("instagram", storeInsta);

    storeEmail !== user.email && formData.append("email", storeEmail);

    storeCardDiscount !== user.card_discount &&
      formData.append("card_discount", storeCardDiscount);
    formData.append("location", `${storeLatitude}, ${storeLongitude}`);
    StoreLogoToUpload !== null && formData.append("image", StoreLogoToUpload);

    if (storePayment !== user.payment) {
      formData.append("payment_getway", storePayment);
    }

    axiosPost(
      `${storesUrl}/${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditStore,
      setIsOpen,
      [setEmpty],
      [setEmpty]
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {roles.includes("Create-Product") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenNewProduct}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-plus" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="إضافة منتج"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {/* Delete user */}
        {roles.includes("Delete-Brand") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenDeleteStore}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف المتجر"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        <Dialog
          fullScreen
          disableEscapeKeyDown
          open={openNewProduct}
          onClose={handleCloseNewProduct}
        >
          <DialogTitle>إضافة منتج جديد</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="اسم المنتج"
                  variant="outlined"
                  onChange={handleChangeProductName}
                  value={productName}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="الوصف"
                  variant="outlined"
                  multiline
                  rows={2}
                  onChange={handleChangeDescription}
                  value={description}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="السعر"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangePrice}
                  value={price}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="سعر التخفيض"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangeDiscountPrice}
                  value={discountPrice}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  العلامة التجارية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={brandId}
                  onChange={handleChangeBrandId}
                  input={<OutlinedInput label="العلامة التجارية" />}
                >
                  {!isLoadingBrands
                    ? brandsList.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  الفئة الرئيسية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={categoryId}
                  onChange={handleChangeCategoryId}
                  input={<OutlinedInput label="الفئة الرئيسية" />}
                >
                  {!isLoadingCategories
                    ? categoriesList.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  الفئة الفرعية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={subCategoryId}
                  onChange={handleChangeSubCategoryId}
                  input={<OutlinedInput label="الفئة الفرعية" />}
                >
                  {subCategoriesList.length > 0 ? (
                    subCategoriesList[0].subcategorys.length > 0 ? (
                      subCategoriesList[0].subcategorys.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""} disabled>
                        لا يوجد فئات فرعية
                      </MenuItem>
                    )
                  ) : null}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">{storeName}</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={storeId}
                  onChange={handleChangeStoreId}
                  input={<OutlinedInput label="المتجر" />}
                >
                        <MenuItem value={storeId} key={storeId}>
                          {storeName}
                        </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Divider sx={{ margin: "1rem 0" }} />
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>صورة المنتج الرئيسية</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewProductImage ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewProductImage}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewProductImage(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      ارفع صورة المنتج الرئيسية
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureProductImage}
                      />
                    </Button>
                  )}
                  {productImageToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${productImageToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setProductImageToShow(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
            <Divider sx={{ margin: "1rem 0" }} />
            <Typography sx={{ marginBottom: "1rem" }}>
              معرض صور المنتجات
            </Typography>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <Button
                    variant={"outlined"}
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    انقر او اسحب هنا
                  </Button>
                  &nbsp;
                  <Button variant={"outlined"} onClick={onImageRemoveAll}>
                    مسح جميع الصور
                  </Button>
                  <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                    {imageList.map((image, index) => (
                      <Box
                        key={index}
                        className="image-item"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        {/* <img src={image["data_url"]} alt="" width="100" /> */}
                        <Avatar
                          src={image["data_url"]}
                          alt=""
                          sx={{ width: 200, height: 200 }}
                        />
                        <div className="image-item__btn-wrapper">
                          <Button
                            variant={"outlined"}
                            onClick={() => onImageUpdate(index)}
                          >
                            تعديل
                          </Button>
                          <Button
                            sx={{ margin: "0 0.5rem" }}
                            variant={"outlined"}
                            onClick={() => onImageRemove(index)}
                          >
                            حذف
                          </Button>
                        </div>
                      </Box>
                    ))}
                  </Box>
                </div>
              )}
            </ImageUploading>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseNewProduct}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitAddProduct}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog
          disableEscapeKeyDown
          open={openDeleteStore}
          onClose={handleCloseDeleteStore}
        >
          <DialogTitle>حذف المتجر</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف هذا المتجر ؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteStore}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteStore}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        {roles.includes("Update-Brand") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditStore}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل المتجر"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openEditStore}
          onClose={handleCloseEditStore}
        >
          <DialogTitle>تعديل بيانات المتجر</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="اسم المتجر"
                  variant="outlined"
                  onChange={handleChangeStoreName}
                  value={storeName}
                />
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel id="demo-dialog-select-label">المحافظة</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={storeCity}
                  onChange={handleChangeStoreCity}
                  input={<OutlinedInput label="المحافظة" />}
                >
                  <MenuItem value={"Tartous"}>طرطوس</MenuItem>
                  <MenuItem value={"Lattakia"}>اللاذقية</MenuItem>
                  <MenuItem value={"Damascus"}>دمشق</MenuItem>
                  <MenuItem value={"Damascus_countryside"}>ريف دمشق</MenuItem>
                  <MenuItem value={"Aleppo"}>حلب</MenuItem>
                  <MenuItem value={"Homs"}>حمص</MenuItem>
                  <MenuItem value={"Hama"}>حماه</MenuItem>
                  <MenuItem value={"Idlib"}>ادلب</MenuItem>
                  <MenuItem value={"Suwayda"}>السويداء</MenuItem>
                  <MenuItem value={"Daraa"}>درعا</MenuItem>
                  <MenuItem value={"Quneitra"}>القنيطرة</MenuItem>
                  <MenuItem value={"Deir_Ezzor"}>دير الزور</MenuItem>
                  <MenuItem value={"Al_Hasakah"}>الحسكة</MenuItem>
                  <MenuItem value={"Raqqa"}>الرقة</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="خط العرض"
                  variant="outlined"
                  value={storeLatitude}
                  disabled
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="خط الطول"
                  variant="outlined"
                  value={storeLongitude}
                  disabled
                />
              </FormControl>
            </Box>
            <GetUserLocationEdit
              longitude={storeLongitude}
              latitude={storeLatitude}
              setLatitude={setStoreLatitude}
              setLongitude={setStoreLongitude}
            />

            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="رقم الهاتف"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangeStorePhoneNumber}
                  value={storePhoneNumber}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="حساب فيس بوك للمتجر"
                  variant="outlined"
                  onChange={handleChangeStoreFacebook}
                  value={storeFacebook}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="حساب انستاغرام للمتجر"
                  variant="outlined"
                  onChange={handleChangeStoreInsta}
                  value={storeInsta}
                />
              </FormControl>
            </Box>

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="البريد الالكتروني للمتجر"
                  variant="outlined"
                  onChange={handleChangeStoreEmail}
                  value={storeEmail}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <TextField
                  label="قسمة خصم البطاقة"
                  variant="outlined"
                  onChange={handleChangeStoreCardDiscount}
                  value={storeCardDiscount}
                />
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel id="demo-dialog-select-label">طرق الدفع</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={storePayment}
                  multiple
                  onChange={handleChangePaymentArray}
                  input={<OutlinedInput label="طرق الدفع" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"cach"} key={"cach"}>
                    <Checkbox checked={storePayment?.indexOf("cach") > -1} />
                    <ListItemText primary={"cach"} />
                  </MenuItem>
                  <MenuItem value={"syriatel"} key={"syriatel"}>
                    <Checkbox
                      checked={storePayment?.indexOf("syriatel") > -1}
                    />
                    <ListItemText primary={"syriatel"} />
                  </MenuItem>
                  <MenuItem value={"mtn"} key={"mtn"}>
                    <Checkbox checked={storePayment?.indexOf("mtn") > -1} />
                    <ListItemText primary={"mtn"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>صورة شعار المتجر</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewStoreLogo ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewStoreLogo}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewStoreLogo(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      ارفع صورة شعار المتجر
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureStoreLogo}
                      />
                    </Button>
                  )}
                  {StoreLogoToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${StoreLogoToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setStoreLogoToShow(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditStore}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditStore}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
