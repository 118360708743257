import * as Yup from "yup";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
// login function
import { login } from "../../../controller/AuthController";
//
import useAuth from "../../../hooks/useAuth";
import palette from "../../../theme/palette";
import { updateFcmToken } from "../../../constants/urls";
import { requestForToken } from "../../../firebase";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const loginUser = async () => {
    const result = await login(formik.values.username, formik.values.password);

    if (result.status === 200) {
      requestForToken(result.data.data.authorization.token);
      const username = result.data.data.user.name;
      const roles = result.data.data.permissions;
      setAuth({ username, roles });
      localStorage.setItem("SUN", result.data.data.user.name);
      localStorage.setItem("SRN", result.data.data.user.role_name);
      localStorage.setItem("SUID", result.data.data.user.id);
      localStorage.setItem("SRS", JSON.stringify(result.data.data.permissions));

      localStorage.setItem("STKN", result.data.data.authorization.token);

      result.data.data.user.role_name === "SuperAdmin"
        ? navigate("/dashboard/app", { replace: true })
        : navigate("/dashboard/my-store", { replace: true });
    } else {
      setError(result);
      setShowError(true);
      formik.setSubmitting(false);
    }
  };
  const LoginSchema = Yup.object().shape({
    password: Yup.string().required("يرجى ادخال كلمة المرور"),
    username: Yup.number()
      .required("يرجى ادخال رقم الهاتف")
      .typeError("يرجى إدخال أرقام فقط"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      loginUser();
    },
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ outlineColor: palette.primary.main }}>
          {showError ? (
            <div
              style={{
                color: "red",
                border: "1px solid red",
                padding: "10px",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              {error}
            </div>
          ) : (
            ""
          )}
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="رقم الهاتف"
            {...getFieldProps("username")}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
            sx={{
              "& .MuiFormHelperText-root": {
                textAlign: "right",
              },
            }}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="كلمة المرور"
            {...getFieldProps("password")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={handleShowPassword} edge="start">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingLeft: 0,
              },
              "& .MuiFormHelperText-root": {
                textAlign: "right",
              },
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        ></Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            color: palette.primary.secondaryDark,
            backgroundColor: palette.primary.main,
          }}
        >
          تسجيل الدخول
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
