// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
//
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AppBugReports({ DailyNewUsers }) {
  let icon =
    DailyNewUsers?.status === "pending"
      ? "material-symbols:pending-actions"
      : DailyNewUsers?.status === "delivery_pending"
      ? "tabler:truck-delivery"
      : DailyNewUsers?.status === "received"
      ? "mdi:package-delivered"
      : DailyNewUsers?.status === "rejected"
      ? "fluent-mdl2:deactivate-orders"
      : DailyNewUsers?.status === "new"
      ? "icon-park-outline:transaction-order"
      : DailyNewUsers?.status;

  let rootColor =
    DailyNewUsers?.status === "pending"
      ? "#212B36"
      : DailyNewUsers?.status === "delivery_pending"
      ? "#7a4f01"
      : DailyNewUsers?.status === "received"
      ? "#005249"
      : DailyNewUsers?.status === "rejected"
      ? "#7A0C2E"
      : DailyNewUsers?.status === "new"
      ? "#1939B7"
      : DailyNewUsers?.status;

  let rootBackgroundColor =
    DailyNewUsers?.status === "pending"
      ? "#FFE16A"
      : DailyNewUsers?.status === "delivery_pending"
      ? "#fff7cd"
      : DailyNewUsers?.status === "received"
      ? "#c8facd"
      : DailyNewUsers?.status === "rejected"
      ? "#FFA48D"
      : DailyNewUsers?.status === "new"
      ? "#D6E4FF"
      : DailyNewUsers?.status;

  let rootIconColor =
    DailyNewUsers?.status === "pending"
      ? "material-symbols:pending-actions"
      : DailyNewUsers?.status === "delivery_pending"
      ? "#7a4f01"
      : DailyNewUsers?.status === "received"
      ? "#005249"
      : DailyNewUsers?.status === "rejected"
      ? "#7A0C2E"
      : DailyNewUsers?.status === "new"
      ? "#1939B7"
      : DailyNewUsers?.status;

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    padding: theme.spacing(5, 0),
    color: rootColor,
    backgroundColor: rootBackgroundColor,
  }));

  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: rootIconColor,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.info.dark,
      0
    )} 0%, ${alpha(theme.palette.info.dark, 0.24)} 100%)`,
  }));

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Iconify icon={icon} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">
        {fShortenNumber(DailyNewUsers?.ordersStatus)}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {DailyNewUsers?.status === "pending"
          ? "طلبات معلقة"
          : DailyNewUsers?.status === "delivery_pending"
          ? "طلبات قيد التوصيل"
          : DailyNewUsers?.status === "received"
          ? "طلبات مسلمة"
          : DailyNewUsers?.status === "rejected"
          ? "طلبات مرفوضة"
          : DailyNewUsers?.status === "new"
          ? "طلبات جديدة"
          : DailyNewUsers?.status}
      </Typography>
    </RootStyle>
  );
}
