import { useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Avatar,
  Typography,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { BrandsUrl, storageUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";

// ----------------------------------------------------------------------

export default function BrandMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Delete user
  */
  const [openDeleteBrand, setOpenDeleteBrand] = useState(false);

  const handleClickopenDeleteBrand = () => {
    setOpenDeleteBrand(true);
  };

  const handleCloseDeleteBrand = () => {
    setOpenDeleteBrand(false);
  };

  const handleSubmitDeleteBrand = () => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${BrandsUrl}/${user.id}`,
      setLoadingUserBtn,
      setOpenDeleteBrand
    );
  };

  /* 
     Update User
  */

  const [openEditUser, setOpenEditBrand] = useState(false);
  const [Username, setUsername] = useState(user.name);

  const [BrandLogoToShow, setBrandLogoToShow] = useState(user.logo);
  const [previewBrandLogo, setPreviewBrandLogo] = useState(null);
  const [BrandLogoToUpload, setBrandLogoToUpload] = useState(null);

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleClickOpenEditbrand = () => {
    setOpenEditBrand(true);
  };

  const handleCloseEditBrand = () => {
    setOpenEditBrand(false);
  };

  const handleCaptureBrandLogo = (e) => {
    setBrandLogoToShow(null);
    setBrandLogoToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewBrandLogo(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [empty, setEmpty] = useState([]);

  const handleSubmitEditBrand = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    /* Username !== user.name && */ formData.append("name", Username);
    BrandLogoToUpload !== null && formData.append("logo", BrandLogoToUpload);

    axiosPost(
      `${BrandsUrl}/${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditBrand,
      setIsOpen,
      [setEmpty],
      [setEmpty]
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Delete user */}
        {roles.includes("Delete-Brand") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenDeleteBrand}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف العلامة التجارية"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openDeleteBrand}
          onClose={handleCloseDeleteBrand}
        >
          <DialogTitle>حذف العلامة التجارية</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف هذه العلامة التجارية؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteBrand}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteBrand}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        {roles.includes("Update-Brand") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditbrand}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل العلامة التجارية"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openEditUser}
          onClose={handleCloseEditBrand}
        >
          <DialogTitle>تعديل بيانات العلامة التجارية</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="اسم العلامة التجارية"
                  variant="outlined"
                  onChange={handleChangeUsername}
                  value={Username}
                />
              </FormControl>
            </Box>

            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>صورة العلامة التجارية</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewBrandLogo ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewBrandLogo}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewBrandLogo(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      ارفع صورة العلامة التجارية
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureBrandLogo}
                      />
                    </Button>
                  )}
                  {BrandLogoToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${BrandLogoToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setBrandLogoToShow(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditBrand}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditBrand}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
