import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../../components/charts";

// ----------------------------------------------------------------------

export default function AppConversionRates({ userCity }) {
  const ChartData = [];
  const Categories = [];
  userCity?.map((item) => {
    ChartData.push(item.governorateUsers);

    switch (item.governorate) {
      case "Damascus_countryside":
        Categories.push("ريف دمشق");
        break;
      case "Damascus":
        Categories.push("دمشق");
        break;
      case "Quneitra":
        Categories.push("القنيطرة");
        break;
      case "Daraa":
        Categories.push("درعا");
        break;
      case "Deir_Ezzor":
        Categories.push("دير الزور");
        break;
      case "Homs":
        Categories.push("حمص");
        break;
      case "Hama":
        Categories.push("حماه");
        break;
      case "Aleppo":
        Categories.push("حلب");
        break;
      case "Al_Hasakah":
        Categories.push("الحسكة");
        break;
      case "Lattakia":
        Categories.push("اللاذقية");
        break;
      default:
        Categories.push(item.governorate);
        break;
    }
  });

  const CHART_DATA = [{ data: ChartData }];
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => `#`,
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
    },
    xaxis: {
      categories: Categories,
    },
  });

  return (
    <Card>
      <CardHeader title="عدد المستخدمين في المحافظات" />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATA}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
