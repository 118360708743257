import { useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
import "../../../App.css";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import {
  addShippingCostUrl,
  CategoriesUrl,
  OrdersUrl,
  userListUrl,
} from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";
import Logo from "../../../components/Logo";
import axiosGet from "../../../utils/axiosGet";

// ----------------------------------------------------------------------

export default function OrdersMoreMenu({
  user,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
}) {
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  let date = new Date();
  /* 
     Delete user
  */
  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  const handleClickopenOrderDetails = () => {
    axiosGet(
      `${OrdersUrl}/details/${user.id}`,
      setOrderDetails,
      setIsLoading,
      setError,
      setError
    );
    setOpenOrderDetails(true);
  };
  const handleCloseOrderDetails = () => {
    setOpenOrderDetails(false);
  };

  const handleDetailsPrint = () => {
    window.print();
  };

  /* 
     Order Status
  */

  const [openEditOrderStatus, setOpenEditOrderStatus] = useState(false);
  const [orderStatus, setOrderStatus] = useState(user.status);

  const handleChangeOrderStatus = (event) => {
    setOrderStatus(event.target.value);
  };

  const handleClickOpenEditOrderStatus = () => {
    setOpenEditOrderStatus(true);
  };

  const handleCloseEditOrderStatus = () => {
    setOpenEditOrderStatus(false);
  };

  const handleSubmitEditOrderStatus = () => {
    setLoadingUserBtn(true);
    const value = {
      status: orderStatus,
    };

    axiosPost(
      `${OrdersUrl}/${user.id}?_method=PUT`,
      value,
      setLoadingUserBtn,
      setOpenEditOrderStatus,
      setIsOpen
    );
  };

  /* 
     shipping cost
  */

  // const [openEditShippingCost, setOpenEditShippingCost] = useState(false);
  // const [ShippingCost, setShippingCost] = useState(user.user.shipping_cost);

  // const handleChangeShippingCost = (event) => {
  //   setShippingCost(event.target.value);
  // };

  // const handleClickOpenEditShippingCost = () => {
  //   setOpenEditShippingCost(true);
  // };

  // const handleCloseEditShippingCost = () => {
  //   setOpenEditShippingCost(false);
  // };
  // const handleSubmitEditShippingCost = () => {
  //   setLoadingUserBtn(true);
  //   const value = {
  //     user_id: user.user.id,
  //     order_id: user.id,
  //     cost: ShippingCost,
  //   };

  //   axiosPost(
  //     `${addShippingCostUrl}`,
  //     value,
  //     setLoadingUserBtn,
  //     setOpenEditOrderStatus,
  //     setIsOpen
  //   );
  // };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Delete user */}
        {roles.includes("Order-details") && (
          <MenuItem
            id="printPageButton"
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenOrderDetails}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="bx:show" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="عرض تفاصيل الطلب"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {/* Details Dialog */}
        <Dialog
          fullWidth
          open={openOrderDetails}
          onClose={handleCloseOrderDetails}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <Typography sx={{ fontWeight: "800", fontSize: "1.2rem" }}>
                متجر شكراً
              </Typography>
              <Logo sx={{ width: 100, height: 100 }} />
            </Box>
          </DialogTitle>
          {error ? (
            <Typography sx={{ fontWeight: "800", fontSize: "1.2rem" }}>
              {error}
            </Typography>
          ) : !isLoading ? (
            orderDetails.length > 0 ? (
              <DialogContent id="alert-dialog-description">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          السيد/ة
                        </TableCell>
                        <TableCell
                          align={i18n.dir() === "ltr" ? "right" : "left"}
                        >
                          {user.user.name}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          المنتج
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          الكمية
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          السعر
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          السعر بعد التخفيض
                        </TableCell>
                      </TableRow>
                      {orderDetails[0]?.products?.map((item, index) => (
                        <>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            key={index}
                          >
                            <TableCell
                              sx={{ textAlign: "right", width: "40%" }}
                            >
                              {item?.name}
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              {item.quantity}
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              {item.price} ل.س
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              {item.discount_price} ل.س
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                      <TableRow>
                        <TableCell sx={{ textAlign: "right", width: "40%" }}>
                          <h3>ملاحظات </h3> {orderDetails[0]?.note}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          القيمة الاجمالية
                        </TableCell>
                        <TableCell
                          scope="row"
                          align={i18n.dir() === "ltr" ? "right" : "left"}
                        >
                          {orderDetails[0].price} ل.س
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          قيمة خصم البطاقة
                        </TableCell>
                        <TableCell
                          scope="row"
                          align={i18n.dir() === "ltr" ? "right" : "left"}
                        >
                          {orderDetails[0].card_discount_price} ل.س
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          تكاليف الشحن
                        </TableCell>
                        <TableCell
                          scope="row"
                          align={i18n.dir() === "ltr" ? "right" : "left"}
                        >
                          {orderDetails[0].shipping_cost === null
                            ? "لا يوجد"
                            : `${orderDetails[0].shipping_cost} ل.س`}
                        </TableCell> */}
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          scope="row"
                          align={i18n.dir() === "ltr" ? "left" : "right"}
                        >
                          المجموع النهائي
                        </TableCell>
                        <TableCell
                          scope="row"
                          align={i18n.dir() === "ltr" ? "right" : "left"}
                        >
                          {parseInt(orderDetails[0].price) -
                            parseInt(orderDetails[0].card_discount_price)}{" "}
                          ل.س
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ marginTop: "2rem" }}>
                  التاريخ : {orderDetails[0]?.created_at}
                </Box>
              </DialogContent>
            ) : (
              <Typography
                sx={{
                  fontWeight: "800",
                  fontSize: "1.2rem",
                  padding: "0 1rem",
                }}
              >
                لا يوجد بيانات لعرضها
              </Typography>
            )
          ) : (
            <CircularProgress sx={{ margin: "1rem auto" }} />
          )}

          <DialogActions id="printPageButton" className="hidePrint">
            <Button
              variant="outlined"
              onClick={handleCloseOrderDetails}
              id="printPageButton"
            >
              إغلاق
            </Button>
            <Button
              id="printPageButton"
              variant="outlined"
              sx={{ margin: "0 1rem" }}
              onClick={handleDetailsPrint}
              autoFocus
            >
              طباعة
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit user */}
        {roles.includes("Order-Update") && (
          <MenuItem
            id="printPageButton"
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditOrderStatus}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="tabler:status-change" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل حالة الطلب"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          id="printPageButton"
          disableEscapeKeyDown
          open={openEditOrderStatus}
          onClose={handleCloseEditOrderStatus}
        >
          <DialogTitle>تعديل حالة الطلب</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-dialog-select-label">
                  حالة الطلب
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={orderStatus}
                  onChange={handleChangeOrderStatus}
                  input={<OutlinedInput label="حالة الطلب" />}
                >
                  <MenuItem value={"طلب جديد"}>طلب جديد</MenuItem>
                  <MenuItem value={"طلب معلق"}>طلب معلق</MenuItem>
                  <MenuItem value={"طلب قيد التوصيل"}>طلب قيد التوصيل</MenuItem>
                  <MenuItem value={"طلب مستلم"}>طلب مستلم</MenuItem>
                  <MenuItem value={"طلب مرفوض"}>طلب مرفوض</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditOrderStatus}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditOrderStatus}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/*  user shipping cost */}
        {/* {roles.includes("user-edit") && (
          <MenuItem
            id="printPageButton"
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditShippingCost}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="mdi:local-shipping" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل تكاليف الشحن"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          id="printPageButton"
          disableEscapeKeyDown
          open={openEditShippingCost}
          onClose={handleCloseEditShippingCost}
        >
          <DialogTitle>تعديل تكاليف الشحن</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="تكاليف الشحن"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangeShippingCost}
                  value={ShippingCost}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditShippingCost}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditShippingCost}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog> */}
      </Menu>
    </>
  );
}
