import { useEffect, useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Avatar,
  Typography,
  CircularProgress,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import {
  BrandsUrl,
  CategoriesUrl,
  ProductsUrl,
  SlidersUrl,
  storageUrl,
  SubCategoriesUrl,
} from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";
import axiosDelete from "../../../utils/axiosDelete";
import axiosPost from "../../../utils/axiosPost";
import axiosGet from "../../../utils/axiosGet";

// ----------------------------------------------------------------------

export default function SliderMoreMenu({
  user,
  token,
  setLoadingUserBtn,
  loadingUserBtn,
  roles,
  types,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /* 
     Delete user
  */
  const [openDeleteSlider, setOpenDeleteSlider] = useState(false);
  // console.log("user", user);
  const handleClickopenDeleteSlider = () => {
    setOpenDeleteSlider(true);
  };

  const handleCloseDeleteSlider = () => {
    setOpenDeleteSlider(false);
  };

  const handleSubmitDeleteSlider = () => {
    setLoadingUserBtn(true);
    axiosDelete(
      `${SlidersUrl}/${user.id}`,
      setLoadingUserBtn,
      setOpenDeleteSlider
    );
  };

  /* 
     Update Slider
  */

  const [openEditSlider, setOpenEditSlider] = useState(false);
  const [error, setError] = useState(null);

  const [targetList, setTargetList] = useState([]);
  const [isLoadingTargetList, setIsLoadingTargetList] = useState(true);

  const [type, setType] = useState("");
  const [inApp, setInApp] = useState(user.inApp);
  const [Url, setUrl] = useState(user.URL);
  const [target_id, setTarget_id] = useState(user.target_id);

  const [sliderLogoToShow, setSliderLogoToShow] = useState(user.path);
  const [previewSliderLogo, setPreviewSliderLogo] = useState(null);
  const [sliderLogoToUpload, setSliderLogoToUpload] = useState(null);

  let currentType = "";
  useEffect(() => {
    switch (user.type) {
      case "Brand":
        return (currentType = "brands");
      case "Category":
        return (currentType = "categories");
      case "Subcategory":
        return (currentType = "subcategories");
      case "Product":
        return (currentType = "products");
    }
  }, []);
  useEffect(() => {
    type === "" && setType(currentType);
    if (type === "brands") {
      axiosGet(BrandsUrl, setTargetList, setIsLoadingTargetList, setError);
    } else if (type === "categories") {
      axiosGet(CategoriesUrl, setTargetList, setIsLoadingTargetList, setError);
    } else if (type === "subcategories") {
      axiosGet(
        SubCategoriesUrl,
        setTargetList,
        setIsLoadingTargetList,
        setError
      );
    } else if (type === "products") {
      axiosGet(ProductsUrl, setTargetList, setIsLoadingTargetList, setError);
    } else return null;
  }, [type]);

  const handleChangeUrl = (event) => {
    setUrl(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
    setIsLoadingTargetList(true);
  };

  const handleChangeTarget_id = (event) => {
    setTarget_id(event.target.value);
  };

  const handleChangeInApp = (event) => {
    setInApp(event.target.value);
    if (event.target.value === "0") {
      setType("");
      setTarget_id("");
    } else if (event.target.value === "1") {
      setUrl("");
    }
  };

  const handleClickOpenEditSlider = () => {
    setOpenEditSlider(true);
  };

  const handleCloseEditSlider = () => {
    setOpenEditSlider(false);
  };

  const handleCaptureSliderLogo = (e) => {
    setSliderLogoToShow(null);
    setSliderLogoToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewSliderLogo(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmitEditSlider = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    inApp !== user.inApp && formData.append("inApp", inApp);
    type !== user.type && formData.append("type", type);
    Url !== user.URL && formData.append("URL", Url);
    target_id !== user.target_id && formData.append("target_id", target_id);
    sliderLogoToUpload !== null && formData.append("image", sliderLogoToUpload);

    axiosPost(
      `${SlidersUrl}/${user.id}?_method=PUT`,
      formData,
      setLoadingUserBtn,
      setOpenEditSlider,
      setIsOpen
    );
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Delete user */}
        {roles.includes("Delete-slider") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickopenDeleteSlider}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="ic:baseline-delete" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="حذف السلايدر"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openDeleteSlider}
          onClose={handleCloseDeleteSlider}
        >
          <DialogTitle>حذف السلايدر</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            هل أنت متأكد من حذف هذا السلايدر ؟
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteSlider}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteSlider}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Edit user */}
        {roles.includes("Update-slider") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleClickOpenEditSlider}
          >
            <ListItemIcon sx={{ marginRight: 0 }}>
              <Iconify icon="eva:edit-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="تعديل السلايدر"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Dialog
          disableEscapeKeyDown
          open={openEditSlider}
          onClose={handleCloseEditSlider}
        >
          <DialogTitle>تعديل السلايدر</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-dialog-select-label">
                  داخل / خارج التطبيق
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={inApp}
                  onChange={handleChangeInApp}
                  input={<OutlinedInput label="داخل / خارج التطبيق" />}
                >
                  <MenuItem value={"1"}>داخل التطبيق</MenuItem>
                  <MenuItem value={"0"}>خارج التطبيق</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {inApp === "1" ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <InputLabel id="demo-dialog-select-label">النوع</InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={type}
                    onChange={handleChangeType}
                    input={<OutlinedInput label="النوع" />}
                  >
                    <MenuItem value={""} disabled>
                      يرجى اختيار نوع
                    </MenuItem>
                    {types.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ) : null}
            {type === "" || type === null ? null : isLoadingTargetList ? (
              <CircularProgress sx={{ margin: "1rem 0" }} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <InputLabel id="demo-dialog-select-label">الهدف</InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={target_id}
                    onChange={handleChangeTarget_id}
                    input={<OutlinedInput label="النوع" />}
                  >
                    <MenuItem value={""} disabled>
                      يرجى اختيار الهدف
                    </MenuItem>
                    {targetList.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            {inApp === "0" ? (
              <Box>
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <TextField
                    id="outlined-basic"
                    label="رابط الوجهة"
                    variant="outlined"
                    value={Url}
                    onChange={handleChangeUrl}
                  />
                </FormControl>
              </Box>
            ) : null}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>صورة السلايدر</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewSliderLogo ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewSliderLogo}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewSliderLogo(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      ارفع صورة السلايدر
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureSliderLogo}
                      />
                    </Button>
                  )}
                  {sliderLogoToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${sliderLogoToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setSliderLogoToShow(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditSlider}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditSlider}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
