import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { storageUrl, storesUrl, userCreateUrl } from "../../../constants/urls";
import DatePicker from "../../../components/DatePicker";
import GetUserLocation from "../user/GetUserLocation";
import { forwardRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import axiosPost from "../../../utils/axiosPost";
import axiosPostStore from "../../../utils/axiosPostStore";
import axiosPostWithActiveStep from "../../../utils/axiosPostWithActiveStep";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const steps = ["انشاء حساب مستخدم المتجر ", "انشاء حساب المتجر"];

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddNewStore({
  loadingUserBtn,
  setLoadingUserBtn,
  roles,
}) {
  const { t, i18n } = useTranslation();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const [openNewStore, setOpenNewStore] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleClickOpenNewStore = () => {
    setOpenNewStore(true);
  };

  const handleCloseNewStore = () => {
    setOpenNewStore(false);
  };

  const [storeName, setStoreName] = useState("");
  const [storeUserId, setStoreUserId] = useState("");
  const [storeCity, setStoreCity] = useState("");
  const [storeLatitude, setStoreLatitude] = useState("");
  const [storeLongitude, setStoreLongitude] = useState("");
  const [storePhoneNumber, setStorePhoneNumber] = useState("");
  const [storeFacebook, setStoreFacebook] = useState("");
  const [storePayment, setStorePayment] = useState([]);
  const [storeInsta, setStoreInsta] = useState("");
  const [storeEmail, setStoreEmail] = useState("");
  const [storeCardDiscount, setStoreCardDiscount] = useState("");
  const [storeLogoToShow, setStoreLogoToShow] = useState();
  const [previewStoreLogo, setPreviewStoreLogo] = useState(null);
  const [storeLogoToUpload, setStoreLogoToUpload] = useState(null);

  const handleChangeStoreName = (event) => {
    setStoreName(event.target.value);
  };

  const handleChangeStoreCity = (event) => {
    setStoreCity(event.target.value);
  };

  const handleChangeStorePhoneNumber = (event) => {
    setStorePhoneNumber(event.target.value);
  };

  const handleChangeStoreFacebook = (event) => {
    setStoreFacebook(event.target.value);
  };

  const handleChangeStoreInsta = (event) => {
    setStoreInsta(event.target.value);
  };

  const handleChangeStoreEmail = (event) => {
    setStoreEmail(event.target.value);
  };

  const handleChangeStoreCardDiscount = (event) => {
    setStoreCardDiscount(event.target.value);
  };

  const handleChangePaymentArray = (e) => {
    const {
      target: { value },
    } = e;
    setStorePayment(typeof value === "string" ? value.split(",") : value);
  };

  const handleCaptureBrandLogo = (e) => {
    setStoreLogoToShow(null);
    setStoreLogoToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewStoreLogo(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const [userLoadingButton, setUserLoadingButton] = useState(false);

  const [Username, setUsername] = useState("");
  const [Phone, setPhone] = useState("");
  const [City, setCity] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [bDate, setBDate] = useState("");
  const [password, setPassword] = useState("");
  // const [shippingCoast, setShippingCoast] = useState("");

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleChangeGovernorate = (event) => {
    setGovernorate(event.target.value);
  };

  const handleChangeLatitude = (event) => {
    setLatitude(event.target.value);
  };

  const handleChangeLongtiude = (event) => {
    setLongitude(event.target.value);
  };

  // const handleChangeShippingCoast = (event) => {
  //   setShippingCoast(event.target.value);
  // };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const [empty, setEmpty] = useState([]);
  const [FalseValue, setFalseValue] = useState([]);

  const handleSubmitAddUser = () => {
    setUserLoadingButton(true);
    const values = {
      name: Username,
      phone_number: Phone,
      password: password,
      password_confirmation: password,
      governorate: governorate,
      city: City,
      address: `${latitude}, ${longitude}`,
      birthday: bDate,
      role_name: "buyer",
      // shipping_cost: shippingCoast,
    };
    axiosPostStore(
      userCreateUrl,
      values,
      setUserLoadingButton,
      setFalseValue,
      setFalseValue,
      [
        setUsername,
        setPhone,
        setPassword,
        setGovernorate,
        setCity,
        setLatitude,
        setLongitude,
        setBDate,
        // setShippingCoast,
      ],
      [setEmpty],
      setStoreUserId,
      setActiveStep,
      setOpenNewStore,
      setSnackBarMessage,
      setOpenSnackBar
    );
  };
  const handleSubmitAddStore = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    formData.append("name", storeName);
    formData.append("user_id", storeUserId);
    formData.append("city", storeCity);
    formData.append("phone_number", storePhoneNumber);
    formData.append("facebook", storeFacebook);
    formData.append("instagram", storeInsta);
    formData.append("email", storeEmail);
    formData.append("card_discount", storeCardDiscount);
    formData.append("location", `${storeLatitude}, ${storeLongitude}`);
    formData.append("image", storeLogoToUpload);

    formData.append("payment_getway", storePayment);

    axiosPostWithActiveStep(
      storesUrl,
      formData,
      setLoadingUserBtn,
      setOpenNewStore,
      setOpenNewStore,
      [
        setStoreName,
        setStoreUserId,
        setStoreCity,
        setStorePhoneNumber,
        setStoreFacebook,
        setStoreLongitude,
        setStoreLatitude,
        setStoreLogoToShow,
        setStoreLogoToUpload,
        setPreviewStoreLogo,
      ],
      [setStorePayment],
      setActiveStep
    );
  };
  return (
    <>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {roles.includes("Create-Brand") && (
        <Button variant="contained" onClick={handleClickOpenNewStore}>
          إضافة جديد
        </Button>
      )}
      <Dialog
        disableEscapeKeyDown
        open={openNewStore}
        onClose={handleCloseNewStore}
      >
        <DialogTitle>إضافة متجر جديد</DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel
                      sx={{
                        "& .MuiStepLabel-iconContainer": {
                          padding: "8px important",
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === 0 ? (
              <>
                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="اسم المستخدم"
                      variant="outlined"
                      onChange={handleChangeUsername}
                      value={Username}
                    />
                  </FormControl>
                </Box>
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="رقم الهاتف"
                      variant="outlined"
                      type={"number"}
                      onChange={handleChangePhone}
                      value={Phone}
                    />
                  </FormControl>
                </Box>
                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="كلمة السر"
                      variant="outlined"
                      onChange={handleChangePassword}
                      type="password"
                      value={password}
                    />
                  </FormControl>
                </Box>

                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <InputLabel id="demo-dialog-select-label">
                      المحافظة
                    </InputLabel>
                    <Select
                      labelId="demo-dialog-select-label"
                      id="demo-dialog-select"
                      value={governorate}
                      onChange={handleChangeGovernorate}
                      input={<OutlinedInput label="المحافظة" />}
                    >
                      <MenuItem value={"Tartous"}>طرطوس</MenuItem>
                      <MenuItem value={"Lattakia"}>اللاذقية</MenuItem>
                      <MenuItem value={"Damascus"}>دمشق</MenuItem>
                      <MenuItem value={"Damascus_countryside"}>
                        ريف دمشق
                      </MenuItem>
                      <MenuItem value={"Aleppo"}>حلب</MenuItem>
                      <MenuItem value={"Homs"}>حمص</MenuItem>
                      <MenuItem value={"Hama"}>حماه</MenuItem>
                      <MenuItem value={"Idlib"}>ادلب</MenuItem>
                      <MenuItem value={"Suwayda"}>السويداء</MenuItem>
                      <MenuItem value={"Daraa"}>درعا</MenuItem>
                      <MenuItem value={"Quneitra"}>القنيطرة</MenuItem>
                      <MenuItem value={"Deir_Ezzor"}>دير الزور</MenuItem>
                      <MenuItem value={"Al_Hasakah"}>الحسكة</MenuItem>
                      <MenuItem value={"Raqqa"}>الرقة</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="المدينة"
                      variant="outlined"
                      onChange={handleChangeCity}
                      value={City}
                    />
                  </FormControl>
                </Box>
                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="خط العرض"
                      variant="outlined"
                      onChange={handleChangeLatitude}
                      value={latitude}
                      disabled
                    />
                  </FormControl>
                </Box>
                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="خط الطول"
                      variant="outlined"
                      onChange={handleChangeLongtiude}
                      value={longitude}
                      disabled
                    />
                  </FormControl>
                </Box>
                <GetUserLocation
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                />

                {/* <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="عمولة الشحن"
                      variant="outlined"
                      onChange={handleChangeShippingCoast}
                      value={shippingCoast}
                    />
                  </FormControl>
                </Box> */}

                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <DatePicker
                      title={"تاريخ الميلاد"}
                      setValue={setBDate}
                      value={bDate}
                      disablePast={false}
                    />
                  </FormControl>
                </Box>
              </>
            ) : (
              <Box>
                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                  <TextField
                    id="outlined-basic"
                    label="اسم المتجر"
                    variant="outlined"
                    onChange={handleChangeStoreName}
                    value={storeName}
                  />
                </FormControl>

                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <InputLabel id="demo-dialog-select-label">
                      المحافظة
                    </InputLabel>
                    <Select
                      labelId="demo-dialog-select-label"
                      id="demo-dialog-select"
                      value={storeCity}
                      onChange={handleChangeStoreCity}
                      input={<OutlinedInput label="المحافظة" />}
                    >
                      <MenuItem value={"Tartous"}>طرطوس</MenuItem>
                      <MenuItem value={"Lattakia"}>اللاذقية</MenuItem>
                      <MenuItem value={"Damascus"}>دمشق</MenuItem>
                      <MenuItem value={"Damascus_countryside"}>
                        ريف دمشق
                      </MenuItem>
                      <MenuItem value={"Aleppo"}>حلب</MenuItem>
                      <MenuItem value={"Homs"}>حمص</MenuItem>
                      <MenuItem value={"Hama"}>حماه</MenuItem>
                      <MenuItem value={"Idlib"}>ادلب</MenuItem>
                      <MenuItem value={"Suwayda"}>السويداء</MenuItem>
                      <MenuItem value={"Daraa"}>درعا</MenuItem>
                      <MenuItem value={"Quneitra"}>القنيطرة</MenuItem>
                      <MenuItem value={"Deir_Ezzor"}>دير الزور</MenuItem>
                      <MenuItem value={"Al_Hasakah"}>الحسكة</MenuItem>
                      <MenuItem value={"Raqqa"}>الرقة</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="خط العرض"
                      variant="outlined"
                      value={storeLatitude}
                      disabled
                    />
                  </FormControl>
                </Box>
                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="خط الطول"
                      variant="outlined"
                      value={storeLongitude}
                      disabled
                    />
                  </FormControl>
                </Box>
                <GetUserLocation
                  setLatitude={setStoreLatitude}
                  setLongitude={setStoreLongitude}
                />
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="رقم الهاتف"
                      variant="outlined"
                      type={"number"}
                      onChange={handleChangeStorePhoneNumber}
                      value={storePhoneNumber}
                    />
                  </FormControl>
                </Box>
                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="حساب فيس بوك للمتجر"
                      variant="outlined"
                      onChange={handleChangeStoreFacebook}
                      value={storeFacebook}
                    />
                  </FormControl>
                </Box>

                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="حساب انستاغرام للمتجر"
                      variant="outlined"
                      onChange={handleChangeStoreInsta}
                      value={storeInsta}
                    />
                  </FormControl>
                </Box>

                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="البريد الالكتروني للمتجر"
                      variant="outlined"
                      onChange={handleChangeStoreEmail}
                      value={storeEmail}
                    />
                  </FormControl>
                </Box>
                <Box component="form">
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <TextField
                      label="قيمة خصم البطاقة"
                      variant="outlined"
                      onChange={handleChangeStoreCardDiscount}
                      value={storeCardDiscount}
                    />
                  </FormControl>
                </Box>
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <InputLabel id="demo-dialog-select-label">
                      طرق الدفع
                    </InputLabel>
                    <Select
                      labelId="demo-dialog-select-label"
                      id="demo-dialog-select"
                      value={storePayment}
                      multiple
                      onChange={handleChangePaymentArray}
                      input={<OutlinedInput label="طرق الدفع" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={"cach"} key={"cach"}>
                        <Checkbox
                          checked={storePayment?.indexOf("cach") > -1}
                        />
                        <ListItemText primary={"cach"} />
                      </MenuItem>
                      <MenuItem value={"syriatel"} key={"syriatel"}>
                        <Checkbox
                          checked={storePayment?.indexOf("syriatel") > -1}
                        />
                        <ListItemText primary={"syriatel"} />
                      </MenuItem>
                      <MenuItem value={"mtn"} key={"mtn"}>
                        <Checkbox checked={storePayment?.indexOf("mtn") > -1} />
                        <ListItemText primary={"mtn"} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    width: "90%",
                    marginTop: "1rem",
                  }}
                >
                  <Typography>شعار المتجر</Typography>
                  <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                    <Box className="upload__image-wrapper">
                      {previewStoreLogo ? (
                        <Box className="image-item">
                          <Avatar
                            src={`${previewStoreLogo}`}
                            alt=""
                            sx={{ width: 200, height: 200, margin: "0 auto" }}
                          />
                          <Box className="image-item__btn-wrapper">
                            <Button
                              sx={{ margin: "1rem 0" }}
                              variant="outlined"
                              onClick={() => setPreviewStoreLogo(null)}
                            >
                              حذف
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          component="label"
                        >
                          ارفع صورة شعار المتجر
                          <input
                            type="file"
                            accept="image/png"
                            hidden
                            onChange={handleCaptureBrandLogo}
                          />
                        </Button>
                      )}
                      {storeLogoToShow && (
                        <Box className="image-item" sx={{ margin: "1rem 0" }}>
                          <Avatar
                            src={`${storageUrl}${storeLogoToShow}`}
                            alt=""
                            sx={{ width: 200, height: 200, margin: "0 auto" }}
                          />
                          <Box className="image-item__btn-wrapper">
                            <Button
                              sx={{ margin: "1rem 0" }}
                              variant="outlined"
                              onClick={() => setStoreLogoToShow(null)}
                            >
                              حذف
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleCloseNewStore}>
            {t("description.Cancel")}
          </Button>

          {activeStep === 0 ? (
            <LoadingButton
              loading={userLoadingButton}
              onClick={handleSubmitAddUser}
            >
              اضافة المستخدم
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitAddStore}
            >
              اضافة المتجر
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
