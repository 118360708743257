import { filter } from "lodash";
import {
  BrandsUrl,
  CategoriesUrl,
  ProductsUrl,
  SlidersUrl,
  storageUrl,
  SubCategoriesUrl,
} from "../constants/urls";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Box,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  LinearProgress,
  Avatar,
  CircularProgress,
} from "@mui/material";

// components

import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import axiosGet from "../utils/axiosGet";
import axiosPost from "../utils/axiosPost";
import SliderMoreMenu from "../sections/@dashboard/sliders/SliderMoreMenu";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Sliders() {
  const { t, i18n } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("SRS"));
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openNewSlider, setOpenNewSlider] = useState(false);
  const [loadingUserBtn, setLoadingUserBtn] = useState(false);
  const [type, setType] = useState("");
  const [inApp, setInApp] = useState("");
  const [Url, setUrl] = useState("");
  const [target_id, setTarget_id] = useState("");

  const [sliderLogoToShow, setSliderLogoToShow] = useState();
  const [previewSliderLogo, setPreviewSliderLogo] = useState(null);
  const [sliderLogoToUpload, setSliderLogoToUpload] = useState(null);

  let navigate = useNavigate();
  const token = localStorage.getItem("STKN");
  const [slidersList, setSlidersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [targetList, setTargetList] = useState([]);
  const [isLoadingTargetList, setIsLoadingTargetList] = useState(true);
  useEffect(() => {
    token === null && navigate("/");
    axiosGet(SlidersUrl, setSlidersList, setIsLoading, setError);
  }, [token, loadingUserBtn]);

  useEffect(() => {
    if (type === "brands") {
      axiosGet(BrandsUrl, setTargetList, setIsLoadingTargetList, setError);
    } else if (type === "categories") {
      axiosGet(CategoriesUrl, setTargetList, setIsLoadingTargetList, setError);
    } else if (type === "subcategories") {
      axiosGet(
        SubCategoriesUrl,
        setTargetList,
        setIsLoadingTargetList,
        setError
      );
    } else if (type === "products") {
      axiosGet(ProductsUrl, setTargetList, setIsLoadingTargetList, setError);
    } else return null;
  }, [type]);

  let filteredUsers = applySortFilter(
    slidersList,
    getComparator(order, orderBy),
    filterName
  );
  const TABLE_HEAD = [
    {
      id: "name",
      label: "المعرف",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "type",
      label: "النوع",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "target_id",
      label: "معرف النوع",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "in_app",
      label: "داخل التطبيق",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "url",
      label: "رابط الوجهة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "logo",
      label: "صورة السلايدر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    { id: "" },
  ];

  const types = [
    {
      name: "علامة تجارية",
      id: "brands",
    },
    {
      name: "فئة رئيسية",
      id: "categories",
    },
    {
      name: "فئة فرعية",
      id: "subcategories",
    },
    {
      name: "منتج",
      id: "products",
    },
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = slidersList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - slidersList.length) : 0;

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) =>
          _user.id.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const isUserNotFound = filteredUsers.length === 0;

  const handleChangeUrl = (event) => {
    setUrl(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
    setIsLoadingTargetList(true);
  };

  const handleChangeTarget_id = (event) => {
    setTarget_id(event.target.value);
  };

  const handleChangeInApp = (event) => {
    setInApp(event.target.value);
    if (event.target.value === "0") {
      setType("");
      setTarget_id("");
    } else if (event.target.value === "1") {
      setUrl("");
    }
  };

  const handleCaptureSliderLogo = (e) => {
    setSliderLogoToShow(null);
    setSliderLogoToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewSliderLogo(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleClickOpenNewSlider = () => {
    setOpenNewSlider(true);
  };

  const handleCloseNewSlider = () => {
    setOpenNewSlider(false);
  };

  const handleSubmitAddSlider = () => {
    setLoadingUserBtn(true);

    const formData = new FormData();
    formData.append("inApp", inApp);
    if (Url !== "") {
      formData.append("URL", Url);
    }
    formData.append("image", sliderLogoToUpload);
    if (type !== "") {
      formData.append("type", type);
    }
    if (target_id !== "") {
      formData.append("target_id", target_id);
    }
    axiosPost(
      SlidersUrl,
      formData,
      setLoadingUserBtn,
      setOpenNewSlider,
      setOpenNewSlider,
      [
        setUrl,
        setSliderLogoToShow,
        setSliderLogoToUpload,
        setPreviewSliderLogo,
        setType,
        setInApp,
        setTarget_id,
      ]
    );
  };
  return isLoading ? (
    <LinearProgress />
  ) : (
    <Page title="لوحة التحكم | السلايدرات">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            السلايدرات
          </Typography>
          {roles.includes("Create-slider") && (
            <Button variant="contained" onClick={handleClickOpenNewSlider}>
              إضافة جديد
            </Button>
          )}
        </Stack>
        <Dialog
          disableEscapeKeyDown
          open={openNewSlider}
          onClose={handleCloseNewSlider}
        >
          <DialogTitle>إضافة سلايدر جديد</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <InputLabel id="demo-dialog-select-label">
                  داخل / خارج التطبيق
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={inApp}
                  onChange={handleChangeInApp}
                  input={<OutlinedInput label="داخل / خارج التطبيق" />}
                >
                  <MenuItem value={"1"}>داخل التطبيق</MenuItem>
                  <MenuItem value={"0"}>خارج التطبيق</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {inApp === "1" ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <InputLabel id="demo-dialog-select-label">النوع</InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={type}
                    onChange={handleChangeType}
                    input={<OutlinedInput label="النوع" />}
                  >
                    <MenuItem value={""} disabled>
                      يرجى اختيار نوع
                    </MenuItem>
                    {types.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ) : null}
            {type === "" || type === null ? null : isLoadingTargetList ? (
              <CircularProgress sx={{ margin: "1rem 0" }} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <InputLabel id="demo-dialog-select-label">الهدف</InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={target_id}
                    onChange={handleChangeTarget_id}
                    input={<OutlinedInput label="النوع" />}
                  >
                    <MenuItem value={""} disabled>
                      يرجى اختيار الهدف
                    </MenuItem>
                    {targetList.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            {inApp === "0" ? (
              <Box>
                <FormControl sx={{ m: 1, minWidth: 350 }}>
                  <TextField
                    id="outlined-basic"
                    label="رابط الوجهة"
                    variant="outlined"
                    value={Url}
                    onChange={handleChangeUrl}
                  />
                </FormControl>
              </Box>
            ) : null}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>صورة السلايدر</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewSliderLogo ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewSliderLogo}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewSliderLogo(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      ارفع صورة السلايدر
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureSliderLogo}
                      />
                    </Button>
                  )}
                  {sliderLogoToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${sliderLogoToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setSliderLogoToShow(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseNewSlider}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitAddSlider}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Card>
          {error ? (
            <Box sx={{ padding: "2rem", color: "red" }}>
              {error.data.message === ""
                ? "حدث خطأ ما يرجى التواصل مع فريق الدعم"
                : error.data.message}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <UserListToolbar
                  placeHolder="ابحث هنا..."
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Box>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={slidersList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const { id, name } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell component="th" scope="row">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      margin:
                                        i18n.dir() === "rtl" &&
                                        "0 0.5rem !important",
                                    }}
                                  >
                                    {id}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <Typography>
                                  {row.type === null ? "لا يوجد" : row.type}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <Typography>
                                  {row.target_id === null
                                    ? "لا يوجد"
                                    : row.target_id}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <Typography>
                                  {row.inApp === "0" ? "لا" : "نعم"}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                <Typography>
                                  {row.URL === null ? "لا يوجد" : row.URL}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    src={`${storageUrl}${row.path}`}
                                    alt=""
                                    sx={{
                                      width: 50,
                                      height: 50,
                                    }}
                                  />
                                </Stack>
                              </TableCell>
                              {/* {roles.includes("user-show") && ( */}
                              <TableCell
                                align={i18n.dir() === "ltr" ? "right" : "left"}
                                padding="none"
                              >
                                <SliderMoreMenu
                                  user={row}
                                  token={token}
                                  loadingUserBtn={loadingUserBtn}
                                  setLoadingUserBtn={setLoadingUserBtn}
                                  roles={roles}
                                  types={types}
                                />
                              </TableCell>
                              {/* )} */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                sx={{ direction: "ltr" }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={slidersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("description.UsersPageLabelRowsPerPage")}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
