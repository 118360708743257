import { filter } from "lodash";
import {
  BrandsUrl,
  CategoriesUrl,
  ProductsCreateUrl,
  ProductsUrl,
  storageUrl,
  SubCategoriesUrl,
} from "../constants/urls";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Box,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  LinearProgress,
  Avatar,
  Divider,
  Pagination,
} from "@mui/material";

// components
import ImageUploading from "react-images-uploading";

import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import axiosGet from "../utils/axiosGet";
import axiosPost from "../utils/axiosPost";
import MyStoreProductsMoreMenu from "../sections/@dashboard/products/MyStoreProductsMoreMenu";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function StoreProducts() {
  const { t, i18n } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("SRS"));
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");

  const [openNewProduct, setOpenNewProduct] = useState(false);
  const [loadingUserBtn, setLoadingUserBtn] = useState(false);
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [brandId, setBrandId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");

  const [productImageToShow, setProductImageToShow] = useState("");
  const [previewProductImage, setPreviewProductImage] = useState(null);
  const [productImageToUpload, setProductImageToUpload] = useState(null);

  let navigate = useNavigate();
  const token = localStorage.getItem("STKN");
  const [productsList, setProductsList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBrands, setIsLoadingBrands] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [error, setError] = useState(null);

  const [images, setImages] = useState([]);
  const maxNumber = 69;
  useEffect(() => {
    token === null && navigate("/");
    axiosGet(
      `${ProductsUrl}?page=${pageNum}`,
      setProductsList,
      setIsLoading,
      setError,
      setTotalPages
    );
  }, [token, loadingUserBtn, pageNum]);
  useEffect(() => {
    token === null && navigate("/");
    axiosGet(BrandsUrl, setBrandsList, setIsLoadingBrands, setError);
    axiosGet(
      CategoriesUrl,
      setCategoriesList,
      setIsLoadingCategories,
      setError
    );
    // axiosGet(
    //   SubCategoriesUrl,
    //   setSubCategoriesList,
    //   setIsLoadingSubCategories,
    //   setError
    // );
  }, []);

  let filteredUsers = applySortFilter(
    productsList,
    getComparator(order, orderBy),
    filterName
  );

  const handleChangePageNum = (event, value) => {
    setIsLoading(true);
    setPageNum(value);
  };

  const TABLE_HEAD = [
    {
      id: "name",
      label: "اسم المنتج",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "description",
      label: "الوصف",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "price",
      label: "السعر",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "discount_price",
      label: "سعر التخفيض",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "brand",
      label: "العلامة التجارية",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "category",
      label: "الفئة الرئيسية",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "sub_category",
      label: "الفئة الفرعية",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "product_image",
      label: "صورة المنتج",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    { id: "" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = productsList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const isUserNotFound = filteredUsers.length === 0;

  const handleChangeProductName = (event) => {
    setProductName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangePrice = (event) => {
    setPrice(event.target.value);
  };

  const handleChangeDiscountPrice = (event) => {
    setDiscountPrice(event.target.value);
  };

  const handleChangeBrandId = (event) => {
    setBrandId(event.target.value);
  };

  const handleChangeCategoryId = (event) => {
    setCategoryId(event.target.value);
    setSubCategoriesList(
      categoriesList.filter((item) => item.id === event.target.value)
    );
  };

  const handleChangeSubCategoryId = (event) => {
    setSubCategoryId(event.target.value);
  };

  const handleCaptureProductImage = (e) => {
    setProductImageToShow(null);
    setProductImageToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewProductImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleClickOpenNewProduct = () => {
    setOpenNewProduct(true);
  };

  const handleCloseNewProduct = () => {
    setOpenNewProduct(false);
  };
  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
  };
  const handleSubmitAddProduct = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    formData.append("name", productName);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("discount_price", discountPrice);
    formData.append("brand_id", brandId);
    formData.append("category_id", categoryId);
    formData.append("subcategory_id", subCategoryId);
    images.length > 0 &&
      images.map((item, i) => {
        formData.append(`images[${i}]`, item.file);
      });
    formData.append("product_photo", productImageToUpload);

    axiosPost(
      ProductsCreateUrl,
      formData,
      setLoadingUserBtn,
      setOpenNewProduct,
      setOpenNewProduct,
      [
        setProductName,
        setProductImageToShow,
        setProductImageToUpload,
        setPreviewProductImage,
        setDescription,
        setPrice,
        setBrandId,
        setDiscountPrice,
        setCategoryId,
        setSubCategoryId,
        setImages,
      ]
    );
  };
  return isLoading ? (
    <LinearProgress />
  ) : (
    <Page title="لوحة التحكم | المنتجات">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            المنتجات
          </Typography>
          {roles.includes("Create-Product") && (
            <Button variant="contained" onClick={handleClickOpenNewProduct}>
              إضافة جديد
            </Button>
          )}
        </Stack>
        <Dialog
          fullScreen
          disableEscapeKeyDown
          open={openNewProduct}
          onClose={handleCloseNewProduct}
        >
          <DialogTitle>إضافة منتج جديد</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="اسم المنتج"
                  variant="outlined"
                  onChange={handleChangeProductName}
                  value={productName}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="الوصف"
                  variant="outlined"
                  multiline
                  rows={2}
                  onChange={handleChangeDescription}
                  value={description}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="السعر"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangePrice}
                  value={price}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <TextField
                  id="outlined-basic"
                  label="سعر التخفيض"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangeDiscountPrice}
                  value={discountPrice}
                />
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  العلامة التجارية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={brandId}
                  onChange={handleChangeBrandId}
                  input={<OutlinedInput label="العلامة التجارية" />}
                >
                  {!isLoadingBrands
                    ? brandsList.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  الفئة الرئيسية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={categoryId}
                  onChange={handleChangeCategoryId}
                  input={<OutlinedInput label="الفئة الرئيسية" />}
                >
                  {!isLoadingCategories
                    ? categoriesList.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: "30%" }}>
                <InputLabel id="demo-dialog-select-label">
                  الفئة الفرعية
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={subCategoryId}
                  onChange={handleChangeSubCategoryId}
                  input={<OutlinedInput label="الفئة الفرعية" />}
                >
                  {subCategoriesList.length > 0 ? (
                    subCategoriesList[0].subcategorys.length > 0 ? (
                      subCategoriesList[0].subcategorys.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""} disabled>
                        لا يوجد فئات فرعية
                      </MenuItem>
                    )
                  ) : null}
                </Select>
              </FormControl>
            </Box>
            <Divider sx={{ margin: "1rem 0" }} />
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>صورة المنتج الرئيسية</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewProductImage ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewProductImage}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewProductImage(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      ارفع صورة المنتج الرئيسية
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureProductImage}
                      />
                    </Button>
                  )}
                  {productImageToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${productImageToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setProductImageToShow(null)}
                        >
                          حذف
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
            <Divider sx={{ margin: "1rem 0" }} />
            <Typography sx={{ marginBottom: "1rem" }}>
              معرض صور المنتجات
            </Typography>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <Button
                    variant={"outlined"}
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    انقر او اسحب هنا
                  </Button>
                  &nbsp;
                  <Button variant={"outlined"} onClick={onImageRemoveAll}>
                    مسح جميع الصور
                  </Button>
                  <Box sx={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                    {imageList.map((image, index) => (
                      <Box
                        key={index}
                        className="image-item"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        {/* <img src={image["data_url"]} alt="" width="100" /> */}
                        <Avatar
                          src={image["data_url"]}
                          alt=""
                          sx={{ width: 200, height: 200 }}
                        />
                        <div className="image-item__btn-wrapper">
                          <Button
                            variant={"outlined"}
                            onClick={() => onImageUpdate(index)}
                          >
                            تعديل
                          </Button>
                          <Button
                            sx={{ margin: "0 0.5rem" }}
                            variant={"outlined"}
                            onClick={() => onImageRemove(index)}
                          >
                            حذف
                          </Button>
                        </div>
                      </Box>
                    ))}
                  </Box>
                </div>
              )}
            </ImageUploading>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseNewProduct}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitAddProduct}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Card>
          {error ? (
            <Box sx={{ padding: "2rem", color: "red" }}>
              {error.data.message === ""
                ? "حدث خطأ ما يرجى التواصل مع فريق الدعم"
                : error.data.message}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <UserListToolbar
                  placeHolder="ابحث هنا..."
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Box>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={productsList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.map((row) => {
                        const { id, name } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align={i18n.dir() === "ltr" ? "left" : "right"}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  margin:
                                    i18n.dir() === "rtl" &&
                                    "0 0.5rem !important",
                                }}
                              >
                                {name}
                              </Typography>
                            </TableCell>

                            <TableCell
                              align={i18n.dir() === "ltr" ? "left" : "right"}
                            >
                              {row.description}
                            </TableCell>
                            <TableCell
                              align={i18n.dir() === "ltr" ? "left" : "right"}
                            >
                              {row.price
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                              ل.س
                            </TableCell>
                            <TableCell
                              align={i18n.dir() === "ltr" ? "left" : "right"}
                            >
                              {row.discount_price !== null
                                ? row.discount_price
                                    .toString()
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                : 0}{" "}
                              ل.س
                            </TableCell>
                            <TableCell
                              align={i18n.dir() === "ltr" ? "left" : "right"}
                            >
                              {row.brand.name}
                            </TableCell>
                            <TableCell
                              align={i18n.dir() === "ltr" ? "left" : "right"}
                            >
                              {row.category.name}
                            </TableCell>
                            <TableCell
                              align={i18n.dir() === "ltr" ? "left" : "right"}
                            >
                              {row.subcategory.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  src={`${storageUrl}${row.product_photo}`}
                                  alt=""
                                  sx={{
                                    width: 50,
                                    height: 50,
                                  }}
                                />
                              </Stack>
                            </TableCell>
                            {/* {roles.includes("user-show") && ( */}
                            <TableCell
                              align={i18n.dir() === "ltr" ? "right" : "left"}
                              padding="none"
                            >
                              <MyStoreProductsMoreMenu
                                user={row}
                                token={token}
                                loadingUserBtn={loadingUserBtn}
                                setLoadingUserBtn={setLoadingUserBtn}
                                roles={roles}
                                brandsList={brandsList}
                                categoriesList={categoriesList}
                                subCategoriesList={subCategoriesList}
                                setSubCategoriesList={setSubCategoriesList}
                                isLoadingBrands={isLoadingBrands}
                                isLoadingCategories={isLoadingCategories}
                              />
                            </TableCell>
                            {/* )} */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Pagination
                count={totalPages}
                page={pageNum}
                onChange={handleChangePageNum}
                sx={{
                  direction: "ltr",
                  "& .MuiPagination-ul": { justifyContent: "center" },
                }}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
