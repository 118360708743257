import { filter } from "lodash";
import { CardsCreateUrl, CardsUrl, CreateCategoryUrl } from "../constants/urls";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Box,
  TextField,
  LinearProgress,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
} from "@mui/material";

// components

import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import axiosGet from "../utils/axiosGet";
import axiosPost from "../utils/axiosPost";
import DatePicker from "../components/DatePicker";
import CardsMoreMenu from "../sections/@dashboard/cards/CardsMoreMenu";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Cards() {
  const { t, i18n } = useTranslation();
  const roles = JSON.parse(localStorage.getItem("SRS"));
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openNewCard, setOpenNewCard] = useState(false);
  const [loadingUserBtn, setLoadingUserBtn] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cardKey, setCardkey] = useState("");
  const [expiryAt, setExpiryAt] = useState("");
  let navigate = useNavigate();
  const token = localStorage.getItem("STKN");
  const [cardsList, setCardsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    token === null && navigate("/");
    axiosGet(CardsUrl, setCardsList, setIsLoading, setError);
  }, [token, loadingUserBtn]);

  let filteredUsers = applySortFilter(
    cardsList,
    getComparator(order, orderBy),
    filterName
  );
  const TABLE_HEAD = [
    {
      id: "name",
      label: "اسم المستخدم",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "phone_number",
      label: "رقم الهاتف",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    {
      id: "card_key",
      label: "رقم البطاقة",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },

    {
      id: "expiry_at",
      label: "تاريخ الانتهاء",
      alignRight: i18n.dir() === "ltr" ? false : true,
    },
    { id: "" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = cardsList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - cardsList.length) : 0;

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) =>
          _user.card_key.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const isUserNotFound = filteredUsers.length === 0;

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangeCardKey = (event) => {
    setCardkey(event.target.value);
  };

  const handleClickOpenNewCategory = () => {
    setOpenNewCard(true);
  };

  const handleCloseNewCategory = () => {
    setOpenNewCard(false);
  };
  const handleSubmitAddCategory = () => {
    setLoadingUserBtn(true);

    const values = {
      phone_number: phoneNumber,
      card_key: cardKey,
      expiry_at: expiryAt,
    };
    axiosPost(
      CardsCreateUrl,
      values,
      setLoadingUserBtn,
      setOpenNewCard,
      setOpenNewCard,
      [setPhoneNumber, setCardkey, setExpiryAt]
    );
  };
  return isLoading ? (
    <LinearProgress />
  ) : (
    <Page title="لوحة التحكم | البطاقات">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            البطاقات
          </Typography>
          {roles.includes("Add-Card") && (
            <Button variant="contained" onClick={handleClickOpenNewCategory}>
              إضافة جديد
            </Button>
          )}
        </Stack>
        <Dialog
          disableEscapeKeyDown
          open={openNewCard}
          onClose={handleCloseNewCategory}
        >
          <DialogTitle>إضافة بطاقة جديدة</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="رقم الهاتف"
                  type={"number"}
                  variant="outlined"
                  onChange={handleChangePhoneNumber}
                  value={phoneNumber}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="رقم البطاقة"
                  variant="outlined"
                  onChange={handleChangeCardKey}
                  value={cardKey}
                />
              </FormControl>
            </Box>

            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <DatePicker
                  title={"تاريخ انتهاء الصلاحية"}
                  setValue={setExpiryAt}
                  value={expiryAt}
                  disablePast={true}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleCloseNewCategory}
              variant={"outlined"}
              sx={{ margin: "0 1rem" }}
            >
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              variant={"outlined"}
              loading={loadingUserBtn}
              onClick={handleSubmitAddCategory}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Card>
          {error ? (
            <Box sx={{ padding: "2rem", color: "red" }}>
              {error.data.message === ""
                ? "حدث خطأ ما يرجى التواصل مع فريق الدعم"
                : error.data.message}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <UserListToolbar
                  placeHolder="ابحث هنا..."
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              </Box>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={cardsList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const { id, name } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell component="th" scope="row">
                                <Typography
                                  variant="subtitle2"
                                  align={
                                    i18n.dir() === "ltr" ? "left" : "right"
                                  }
                                  sx={{
                                    margin:
                                      i18n.dir() === "rtl" &&
                                      "0 0.5rem !important",
                                  }}
                                >
                                  {row.user?.name}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.phone_number}
                              </TableCell>
                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.card_key}
                              </TableCell>

                              <TableCell
                                align={i18n.dir() === "ltr" ? "left" : "right"}
                              >
                                {row.expiry_at}
                              </TableCell>
                              {/* {roles.includes("user-show") && ( */}
                              <TableCell
                                align={i18n.dir() === "ltr" ? "right" : "left"}
                                padding="none"
                              >
                                <CardsMoreMenu
                                  user={row}
                                  token={token}
                                  loadingUserBtn={loadingUserBtn}
                                  setLoadingUserBtn={setLoadingUserBtn}
                                  roles={roles}
                                />
                              </TableCell>
                              {/* )} */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                sx={{ direction: "ltr" }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={cardsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("description.UsersPageLabelRowsPerPage")}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
